import React from 'react'

import Box from './Box'

const DetailBox = ({ firstTitle, secondTitle, thirdTitle, firstInfo, secondInfo, thirdInfo }) => (
  <Box shadow={`${secondInfo ? '' : 'shadow'}`}>
    <div className="flex gap-x-16 py-3 px-6">
      <div className="flex flex-col">
        <div className="flex justify-start p-2 text-left text-xs font-light text-medium-gray sm:text-sm md:text-lg">
          {firstTitle}:
        </div>
        <div className="px-2 text-left text-sm sm:text-base">{firstInfo}</div>
      </div>
      {secondTitle && (
        <div className="flex flex-col">
          <div className="flex justify-start p-2 text-left text-xs font-light text-medium-gray sm:text-sm md:text-lg">
            {secondTitle}:
          </div>
          <div className="px-2 text-left text-sm sm:text-base">{secondInfo}</div>
          {thirdTitle && (
            <>
              <div className="flex justify-start p-2 text-left text-xs font-light text-medium-gray sm:text-sm md:text-lg">
                {thirdTitle}:
              </div>
              <div className="px-2 text-left text-sm sm:text-base">{thirdInfo}</div>
            </>
          )}
        </div>
      )}
    </div>
  </Box>
)

export default DetailBox
