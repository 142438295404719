import { React } from 'react'

import AppVideo from './AppVideo/AppVideo'

const WeighingRecord = ({
  recordPackage,
  setPackageWeightError,
  setIsRecording,
  isRecording
}) => (
    <div className="h-full w-full overflow-hidden bg-light-gray">
      <AppVideo
        recordPackage={recordPackage}
        setPackageWeightError={setPackageWeightError}
        setIsRecording={setIsRecording}
        isRecording={isRecording}
      />
    </div>
  )
export default WeighingRecord
