import React from 'react'

import { SEARCH_ICONS } from '../../constants/icons'

const FilterButton = ({ filtersAmount, openFilters }) => (
  <button
    onClick={openFilters}
    type="button" className={`flex gap-2 rounded-lg p-1 ${filtersAmount > 0 ? 'bg-light-purple' : ''}`}>
    {filtersAmount > 0 &&
      <div className="rounded-md bg-purple px-1.5 text-sm text-white">{filtersAmount}</div>
    }
    <img
      className="h-5 w-5"
      src={filtersAmount > 0 ? SEARCH_ICONS.filterPurple : SEARCH_ICONS.filterGray}
      alt="filter"
    />
  </button>
)

export default FilterButton
