import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import QrScannerLayout from '../../components/QrScanner/QrScannerLayout'
import Button from '../../components/shared/Button'
import { ScanContext } from '../../contexts/ScanContext'
import Modal from '../../components/shared/Modal/Modal'
import ROUTES from '../../constants/routes'
import StoreFlow from '../../components/StoreFlow'
import { storePackage } from '../../helpers/requests/statesChanges'
import Notification from '../../components/shared/Notification'

const StorePackage = () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const isStorePickUp = !!queryParams.get('storePickUp')
  const { codes, singleScan, setScanning } = useContext(ScanContext)
  const [location, setLocation] = useState(null)
  const [storeGroup, setStoreGroup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (codes.length === 0 || Object.keys(codes[0]).length === 0 || !codes[0].pinflagId){
      navigate(ROUTES.IDENTIFY_PACKAGE)
    }
  }, [codes, navigate])

  useEffect(() => {
    setSubmitSuccess(false)
    setSubmitError(false)
  }, [])

  const handleSetGroupScan = () => {
    setScanning(false)
    setStoreGroup(true)
  }

  const makeBody = () => {
    if (isStorePickUp) {
      return { location, packages: [{ packageId: codes[0].pinflagId, storePickup: true }] }
    }
    const packages = codes.map((code) => ({ packageId: code.pinflagId }))
    return { location, packages }
  }

  const storePackages = async () => {
    setLoading(true)
    const body = makeBody()
    try {
      await storePackage(body)
      setSubmitSuccess(true)
    } catch (error) {
      setSubmitError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="h-full w-full overflow-hidden bg-light-gray">
      <div className="h-full">
        <QrScannerLayout
          name="Almacenar"
          color="border-purple"
          backgroundColor="bg-purple"
          setCode={setLocation}
          haveScanned={location !== null}
          codeType="location"
        >
          <StoreFlow
            loading={loading}
            location={location}
            setLocation={setLocation}
            submitSuccess={submitSuccess}
            codes={codes}
            storePackages={storePackages}
          />
        </QrScannerLayout>
        <Modal show={!singleScan && !storeGroup && codes.length > 1}>
          <div className="mt-10 text-xl text-dark-gray">
            Almacena el grupo en una misma ubicación
          </div>
          <div className="my-8 text-medium-gray">Revisa si tienes espacio suficiente</div>
          <Button
            color="bg-blue"
            textColor="text-white"
            shadow="drop-shadow-blue"
            onClick={handleSetGroupScan}
          >
            Hay espacio
          </Button>
          <Link to={ROUTES.IDENTIFY_PACKAGE}>
            <Button textColor="text-text-gray">Ingreso individual</Button>
          </Link>
        </Modal>
        <Notification show={submitError} handleClose={() => setSubmitError(false)} error>
          Ha ocurrido un error
        </Notification>
      </div>
    </div>
  )
}

export default StorePackage
