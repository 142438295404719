import axios from 'axios'

import { API_URL } from '../constants/api'
import { notifyApiError } from '../helpers/requests/pinflagBot'

const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 25000
})

apiClient.interceptors.request.use((config) => {
  const newConfig = config

  if (localStorage.getItem('token')) {
    newConfig.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }
  return newConfig
})

const shouldNotifyError = (error) => (
  !error.config?.signal?.aborted &&
  !error.response?.config?.url?.includes('login-warehouse') &&
  (!error.response || ![401, 404, 409].includes(error.response.status)) &&
  error.response?.data?.message !== 'INCORRECT_PACKAGE_STATUS'
)

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (shouldNotifyError(error)) notifyApiError(error.response || error)

    return Promise.reject(error)
  }
)

export default apiClient
