import React from 'react'

const ScanGrayBox = ({ text, icon }) => (
  <div className="my-2 mx-auto flex w-full max-w-3xl rounded-3xl bg-light-gray py-6 font-light text-medium-gray">
    <div className="m-auto flex w-fit">
      <img
        src={icon}
        className="m-auto ml-2 mr-4 h-6 w-6"
        alt={text}
      />
      {text}
    </div>
  </div>
)

export default ScanGrayBox
