import React from 'react'
import { Link } from 'react-router-dom'

import { FEEDBACK_ICONS } from '../../constants/icons'

import Button from './Button'


const SubmitSuccess = ({text}) => (
  <div className="my-8">
    <img src={FEEDBACK_ICONS.successEndIcon} alt="exitoso" className="mx-auto w-16" />
    <div className="my-6 text-2xl">{text}</div>
    <Link to="/">
      <Button color="bg-green" shadow="drop-shadow-green" textColor="text-white">
        Listo
      </Button>
    </Link>
  </div>
)

export default SubmitSuccess
