import React from 'react'

import PACKAGE_STATUS from '../../constants/packageStatus'
import ROUTES from '../../constants/routes'

import ConditionalLink from './ConditionalLink'

const PackageList = ({ packagesInfo, statusIcon, detail, statusText, name }) => (
  <>
    {packagesInfo.map((currentPackage) => (
      <div key={currentPackage.packageId} className="flex w-full flex-col gap-y-2">
        <ConditionalLink to={`${ROUTES.PACKAGES}/${currentPackage.packageId}`} condition={detail}>
        <div className="flex justify-between">
          <div className="flex w-fit gap-x-5">
            {statusIcon && (
              <img
                className="w-10"
                src={PACKAGE_STATUS[currentPackage.packageStatus].icon}
                alt="status icon"
              />
            )}
            <div className="flex flex-col items-start">
              <div>{currentPackage.orderId}</div>
              <div className="text-sm font-light text-medium-gray md:text-lg xs:text-xs">
                {name && currentPackage.customerFullName}
                {statusText && !name && `${PACKAGE_STATUS[currentPackage.packageStatus].name} - ` }
                {!name && <>Pinflag ID: {currentPackage.pinflagId}</>}
              </div>
            </div>
          </div>
          {detail && (
            <div
              className="rotate-90 text-xl font-semibold text-medium-gray"
            >
              ...
            </div>
          )}
        </div>
        </ConditionalLink>
        <hr />
      </div>
    ))}
  </>
)

export default PackageList
