import React from 'react'

import Button from '../shared/Button'
import Loader from '../shared/Loader'
import SubmitSuccess from '../shared/SubmitSuccess'

import DeliveredDetails from './DeliveredDetails'

const PhotoEvidenceFlow = ({
  submitSuccess,
  loading,
  picture,
  setPicture,
  sendPhotoEvidence,
  deliveredDetails
}) => {
  if (loading) {
    return <Loader />
  }

  if (submitSuccess) {
    return (
      <>
        <SubmitSuccess text="Pedido Entregado" />
        {deliveredDetails && <DeliveredDetails packageDetails={deliveredDetails} />}
      </>
    )
  }

  return (
    <>
      <div className="my-4 text-lg">¿Deseas repetir la foto?</div>
      <img
        src={picture}
        alt="evidencia"
        className="mx-auto my-4 max-h-[70vh] w-[80%] rounded-3xl"
      />
      <div className="mx-2 my-8 flex">
        <Button textColor="text-text-gray" onClick={() => setPicture(null)}>
          Probar otra
        </Button>
        <Button
          onClick={sendPhotoEvidence}
          color="bg-blue"
          textColor="text-white"
          shadow="drop-shadow-blue"
        >
          Finalizar
        </Button>
      </div>
    </>
  )
}

export default PhotoEvidenceFlow
