import React, { useContext, useState } from 'react'

import { ScanContext } from '../../../contexts/ScanContext'
import Modal from '../Modal/Modal'

const ScanInformationBox = ({ title, body, icon }) => {
  const [openDetail, setOpenDetail] = useState(false)
  const { setScanning, codes } = useContext(ScanContext)

  return (
    <>
      <div
        className="my-2 mx-auto flex w-full max-w-3xl rounded-3xl bg-light-gray p-4 text-text-default"
        onClick={() => (codes ? setOpenDetail(true) : undefined)}
        role="button"
        tabIndex="0"
      >
        <img src={icon} alt={title} className="my-auto ml-2 mr-8 h-8 w-8" />
        <div className="text-left">
          <div className="font-light text-text-gray">{title}</div>
          <div>{body}</div>
        </div>
      </div>
      <Modal
        show={openDetail}
        handleClose={() => {
          setOpenDetail(false)
          setScanning(false)
        }}
      >
        <div>
          <div className="my-2 text-lg">Pedidos escaneados</div>
          <div className="max-h-60 overflow-y-auto">
            {codes?.map((code) => (
              <div key={code?.orderId}>
                <p className="border-b-2 py-2 text-sm font-light text-dark-gray md:text-lg">
                  Orden: {code?.orderId} - Pinflag ID: {code?.pinflagId}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  )
}
export default ScanInformationBox
