import React from 'react'

const BottomModal = ({ show, handleClose, children, text, textButton }) => (
    <div className={`fixed left-[0px] top-[0px] z-[10000] flex h-full w-[100vw] items-center justify-center bg-black/50 px-2 ${show ? '' : 'hidden'}`}>
      <div
        className='absolute bottom-0 z-10 flex w-full flex-col items-center justify-center rounded-t-3xl bg-grayish-white p-4 md:p-6'
        role="button"
        tabIndex="0"
      >
        <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_851_3675)">
          <path d="M0.5 35.5C0.5 16.2004 16.2004 0.5 35.5 0.5C54.7996 0.5 70.5 16.2004 70.5 35.5C70.5 54.7996 54.7996 70.5 35.5 70.5C16.2004 70.5 0.5 54.7996 0.5 35.5ZM4.57143 35.5C4.57143 52.555 18.445 66.4286 35.5 66.4286C52.555 66.4286 66.4286 52.555 66.4286 35.5C66.4286 18.445 52.555 4.57143 35.5 4.57143C18.445 4.57143 4.57143 18.445 4.57143 35.5Z" fill="#17C23D" stroke="white"/>
          <path d="M32.6107 39.8761L32.9643 40.2297L33.3178 39.8761L46.7394 26.4546C47.5356 25.6584 48.8216 25.6584 49.6178 26.4546C50.414 27.2508 50.414 28.5368 49.6178 29.333L34.4035 44.5473C34.0063 44.9444 33.4859 45.1438 32.9643 45.1438C32.4427 45.1438 31.9222 44.9444 31.5251 44.5473L23.918 36.9401C23.1218 36.1439 23.1218 34.8579 23.918 34.0617C24.7142 33.2655 26.0001 33.2655 26.7963 34.0617L32.6107 39.8761Z" fill="#17C23D" stroke="white"/>
          </g>
          <defs>
          <clipPath id="clip0_851_3675">
          <rect width="71" height="71" fill="white"/>
          </clipPath>
          </defs>
        </svg>

        <div className='my-[20px] text-[22px] font-semibold text-[#17C23D]'>
          {text}
        </div>

        <div>
          {children}
        </div>
        <div className='mb-[20px] '>
          <button
            type='button'
            onClick={handleClose}
            className='mx-[30px]  mt-4 flex w-[315px] flex-row items-center
            justify-center rounded-full bg-[#0F77F0] py-1 text-center text-[17px] text-white'
          >
            {textButton}
          </button>
        </div>
      </div>
    </div>
  )

export default BottomModal
