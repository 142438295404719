import { React, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import QrScannerLayout from '../components/QrScanner/QrScannerLayout'
import ScanInformationBox from '../components/shared/Box/ScanInformationBox'
import { SCAN_FLOWS_ICONS } from '../constants/icons'
import Button from '../components/shared/Button'
import { ingressPackage } from '../helpers/requests/statesChanges'
import Notification from '../components/shared/Notification'
import { ScanContext } from '../contexts/ScanContext'
import ROUTES from '../constants/routes'
import { defaultMessageError, INGRESS_MESSAGES_ERROR } from '../constants/messages'
import Modal from '../components/shared/Modal/Modal'
import IngressFlow from '../components/IngressFlow'
import { PACKAGE_RECEIVED, PACKAGE_STORED } from '../constants/packageStatus'

const Ingress = () => {
  const { codes, setCodes, singleScan } = useContext(ScanContext)
  const [messageError, setMessageError] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [packageAlreadyIngressed, setPackageAlreadyIngressed] = useState({})
  const [packageAlreadyStored, setPackageAlreadyStored] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    setSubmitSuccess(false)
    setCodes([])
  }, [setCodes])

  const handleGoToStore = () => {
    setCodes([packageAlreadyIngressed])
    navigate(ROUTES.STORAGE)
  }

  const handleIngressResponse = async (ingressResponse) => {
    const { packageCodes, packageStatus, location } = ingressResponse

    if (codes.includes(packageCodes)) setMessageError('Ya ingresaste este código')
    else if (packageStatus === PACKAGE_RECEIVED) setPackageAlreadyIngressed(packageCodes)
    else if (packageStatus === PACKAGE_STORED) setPackageAlreadyStored({packageCodes, location})
    else {
      setCodes([...codes, packageCodes])
      setSubmitSuccess(true)
    }
  }

  const handleIngress = async (textCode) => {
    if (codes.includes(textCode)) {
      setMessageError('Ya ingresaste este código')
      return
    }
    try {
      const ingressResponse = await ingressPackage(textCode)
      handleIngressResponse(ingressResponse)
    } catch (error) {
      setMessageError(INGRESS_MESSAGES_ERROR[error.response?.data?.message] || defaultMessageError)
    }
  }

  return (
    <div className="h-full w-full overflow-hidden bg-light-gray">
      <div className="h-full">
        <QrScannerLayout
          name="Ingresar"
          color="border-green"
          backgroundColor="bg-green"
          singleScan={singleScan}
          validateCode={handleIngress}
          codeType="pinflag"
          haveScanned={codes.length > 0 || Object.keys(packageAlreadyStored).length > 0}
          switchScanType
        >
          <IngressFlow
            packageAlreadyStored={packageAlreadyStored}
            setPackageAlreadyStored={setPackageAlreadyStored}
          />
        </QrScannerLayout>
        <Modal show={packageAlreadyIngressed && Object.keys(packageAlreadyIngressed).length > 0}>
          <div className="mt-10 text-xl text-dark-gray">Pedido ya ingresado</div>
          <div className="my-8 text-medium-gray">
            Este pedido ya se encontraba ingresado, ahora puedes almacenarlo si lo deseas
          </div>
          <ScanInformationBox
            icon={SCAN_FLOWS_ICONS.packageBlue}
            title="Orden de venta"
            body={packageAlreadyIngressed?.orderId}
          />
          <div className="p-1"/>
          <Button
            color="bg-blue"
            textColor="text-white"
            shadow="drop-shadow-blue"
            onClick={handleGoToStore}
          >
            Almacenar
          </Button>
          <Button textColor="text-text-gray" onClick={() => setPackageAlreadyIngressed({})}>
            Almacenar más tarde
          </Button>
        </Modal>
        <Notification show={messageError !== ''} handleClose={() => setMessageError('')} error>
          {messageError}
        </Notification>
        <Notification show={submitSuccess} handleClose={() => setSubmitSuccess(false)}>
          Pedido ingresado con éxito
        </Notification>
      </div>
    </div>
  )
}

export default Ingress
