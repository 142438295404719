import { React } from 'react'
import { Link, useLocation } from 'react-router-dom'

const NotFound = () => {
  const {pathname} = useLocation()

  return (
    <div>
    <div>Not Found: {pathname}</div>
    <Link to="/">Ir a home</Link>
  </div>
  )
}

export default NotFound
