import React, {  useEffect, useState } from 'react'

const CountupTimer = ({
  timerClasses,
  start
}) => {

  const [count, setCount] = useState(0)
  const [time, setTime] = useState('00:00')
  const [intervalTimer, setIntervalTimer] = useState('00:00:00')

  const initTime = new Date()

  const showTimer = (ms) => {
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, '0')
    const minute = Math.floor((ms / 1000 / 60) % 60)
      .toString()
      .padStart(2, '0')
    setTime(
      `${minute  }:${  second  }`
    )
  }

  const clearTime = () => {
    setTime('00:00')
    setCount(0)
  }

  useEffect(() => {
    if (start) {
      const id = setInterval(() => {
        const left = count + (new Date() - initTime)
        setCount(left)
        showTimer(left)
        if (left <= 0) {
          setTime('00:00:00:00')
          clearInterval(id)
        }
      }, 1)
      setIntervalTimer(id)
    }
    if (!start) {
      clearInterval(intervalTimer)
      clearTime()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start])


  return (
    <div className=''>
      <h1 className={timerClasses}>{time}</h1>
    </div>
  )
}

export default CountupTimer
