export const isSubstring = (string, subString) => (
  string
    ?.trim()
    .toLowerCase()
    .includes(subString.trim().toLowerCase())
)

export const shortDate = (longDate) => (longDate ? longDate.slice(0, 10) : '-')

export const formatDate = (date) => new Date(date).toLocaleString('es-CL', { timeZone: 'America/Santiago' })

export const getFormattedDate = (date) => {
  if (!date) return '-'
  const formattedDate = formatDate(date)
  return shortDate(formattedDate)
}
