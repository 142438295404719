import React from 'react'

import { SCAN_FLOWS_ICONS } from '../../constants/icons'
import { locationFormat } from '../../helpers/storage'

import ScanInformationBox from './Box/ScanInformationBox'
import Button from './Button'

const PackageInformation = ({
  title,
  infoText,
  packageBoxTitle,
  packageBoxBody,
  location,
  firstButton,
  secondButton
}) => (
  <>
    <div className="mt-8 text-2xl">{title}</div>
    <div className="my-8 text-sm text-medium-gray">{infoText}</div>
    <ScanInformationBox
      icon={SCAN_FLOWS_ICONS.packageBlue}
      title={packageBoxTitle}
      body={packageBoxBody}
    />
    <ScanInformationBox
      icon={SCAN_FLOWS_ICONS.qrCodeBlue}
      title="Ubicación"
      body={locationFormat(location)}
    />
    <Button
      color="bg-blue"
      textColor="text-white"
      shadow="drop-shadow-blue"
      onClick={firstButton.onClick}
    >{firstButton.text}</Button>
    {secondButton && (
      <Button textColor="text-text-gray" onClick={secondButton.onClick} >{secondButton.text}</Button>
    )}
  </>
)

export default PackageInformation
