import React, { useContext, useEffect, useState } from 'react'

import { cameraMode } from '../../constants/icons'
import { ScanContext } from '../../contexts/ScanContext'

import Header from '../Header'
import LayoutOptions from './LayoutOptions'
import QrScanner from './QrScanner'

const QrScannerLayout = ({
  name,
  color,
  backgroundColor,
  validateCode,
  setCode,
  switchScanType,
  codeType,
  haveScanned,
  children
}) => {
  const { singleScan, scanning, setScanning } = useContext(ScanContext)
  const [loading, setLoading] = useState(false)
  const [showInformation, setShowInformation] = useState(true)
  const [cameraConstrain, setCameraConstrain] = useState({
    video: {
      facingMode: { exact: 'environment' }
    }
  })

  useEffect(() => {
    if (!scanning && !loading) setTimeout(() => setScanning(true), 500)
  }, [loading, scanning, setScanning])

  const handleCode = async (textCode) => {
    if (singleScan && haveScanned) return
    if (codeType !== 'pinflag' && textCode.length !== 7) return
    setScanning(false)
    setShowInformation(true)
    if (validateCode) {
      setLoading(true)
      await validateCode(textCode)
      setScanning(false)
      setLoading(false)
    } else {
      setCode(textCode)
    }
  }

  const handleScan = (scanData) => {
    if (scanData && scanning) {
      handleCode(scanData.text)
    }
  }

  const changeCamera = () => {
    if (cameraConstrain === undefined) {
      setCameraConstrain({
        video: {
          facingMode: { exact: 'environment' }
        }
      })
    } else {
      setCameraConstrain(undefined)
    }
  }

  return (
    <div className="m-auto h-full w-full overflow-hidden bg-grayish-white">
      <Header to="/" name={name} color={backgroundColor} qrScannerStyle/>
      <QrScanner
        color={color}
        handleScan={handleScan}
        handleCode={handleCode}
        codeType={codeType}
        cameraConstrain={cameraConstrain}
        setCameraConstrain={setCameraConstrain}
      />
      <LayoutOptions
        switchScanType={switchScanType}
        haveScanned={haveScanned}
        name={name}
        loading={loading}
        codeType={codeType}
        showInformation={showInformation}
        setShowInformation={setShowInformation}
      >
        {children}
      </LayoutOptions>
      <button
        onClick={changeCamera}
        type="button"
        className={`absolute top-[13%] right-[3%] rounded-full ${backgroundColor} p-4`}
      >
        <img src={cameraMode} alt="tomar foto" className="w-6" />
      </button>
    </div>
  )
}

export default QrScannerLayout
