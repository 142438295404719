import { React, useEffect, useState } from 'react'

import Loader from '../components/shared/Loader'
import LoginForm from '../components/LoginForm'

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [failedLogin, setFailedLogin] = useState(false)

  useEffect(() => {
    setLoading(false)
    setFailedLogin(false)
    return () => {
      setLoading(false)
      setFailedLogin(false)
    }
  }, [])

  return (
    <div className="flex h-screen w-full flex-col justify-center bg-light-gray text-center font-lexend">
      <h1 className="text-2xl font-bold text-text-default">Pinflag <span className="font-light text-purple">pickup</span></h1>
      <div className="mx-4 my-10">
        {loading ? (
          <Loader />
        ) : (
          <LoginForm
            setLoading={setLoading}
            failedLogin={failedLogin}
            setFailedLogin={setFailedLogin}
          />
        )}
      </div>
    </div>
  )
}

export default Login
