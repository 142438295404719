import React, { useRef, useCallback, useState } from 'react'
import Webcam from 'react-webcam'

import { cameraMode, galleryIcon, photoCamera } from '../constants/icons'
import { convertToBase64 } from '../utils/files'

const PhotoCamera = ({ picture, setPicture, children }) => {
  const webcamRef = useRef(null)
  const [cameraConstrain, setCameraConstrain] = useState({ facingMode: 'environment' })

  const capture = useCallback(() => {
    const image = webcamRef.current.getScreenshot()
    setPicture(image)
  }, [setPicture])

  const handleFile = async (files) => {
    const file = files[0]
    if (file.type.includes('image')) {
      const base64 = await convertToBase64(file)
      setPicture(base64)
    }
  }

  const changeCamera = () => {
    if (cameraConstrain.facingMode === 'environment') {
      setCameraConstrain({ facingMode: 'user' })
    } else {
      setCameraConstrain({ facingMode: 'environment' })
    }
  }

  return (
    <div className="absolute top-5 z-0 h-[calc(100%-1.25rem)] w-full overflow-hidden">
      <Webcam
        audio={false}
        className="h-full max-h-screen w-full object-cover"
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={cameraConstrain}
      />
      <label className="absolute bottom-[10%] left-[12%] rounded-full bg-orange p-4 shadow-orange" htmlFor="image">
        <input
          className="hidden"
          type="file"
          name="image"
          id="image"
          onChange={(event) => handleFile(event.target.files)}
          accept=".png,.jpg,.jpeg"
        />
        <img src={galleryIcon} alt="tomar foto" className="w-7" />
      </label >
      <button
        onClick={capture}
        type="button"
        className="absolute bottom-[10%] left-[calc(50%-2.5rem)] rounded-full bg-orange p-6 shadow-orange"
      >
        <img src={photoCamera} alt="tomar foto" className="w-8" />
      </button>
      <button
        onClick={changeCamera}
        type="button"
        className="absolute bottom-[10%] right-[12%] rounded-full bg-orange p-4"
      >
        <img src={cameraMode} alt="tomar foto" className="w-7" />
      </button>
      {picture === null && (
        <div className="absolute top-[20%] w-full text-center text-white">
          Toma al cliente con su pedido
        </div>
      )}
      {picture && (
        <div className="absolute bottom-0 z-10 flex w-full flex-col rounded-t-3xl bg-grayish-white p-4">
          {children}
        </div>
      )}
    </div>
  )
}

export default PhotoCamera
