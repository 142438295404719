import React from 'react'

import Box from './Box'

const ProductsBox = ({ products }) => (
  <Box>
    {products ?
      products.map((product) => (
        <div className="grid grid-cols-4 gap-1 py-4 px-1" key={product.sku}>
          <div className="self-center">
            {
              product.imageUrl ?
              <img src={product.imageUrl} alt="producto" className="m-auto h-auto max-h-24 w-auto max-w-[6rem]"/>
              :
              <div className="mx-1 h-20 w-20 rounded-lg bg-light-gray"/>
            }
          </div>
          <div className="col-span-3 flex flex-col justify-start gap-y-1 text-start">
            <div className="text-sm sm:text-base">{product.name}</div>
            <div className="text-sm font-light text-medium-gray sm:text-base">Cantidad: {product.quantity}</div>
            <div>
              <div className="text-sm font-light text-medium-gray sm:text-base">SKU</div>
              <div className="text-sm sm:text-base">{product.sku}</div>
            </div>
          </div>
        </div>
      ))
    : (
      <div className="p-6 text-sm font-light text-medium-gray">No hay productos disponibles para mostrar</div>
    )}
  </Box>
)

export default ProductsBox
