import React, { useContext, useEffect, useState } from 'react'

import { chevronDownIcon, SCAN_FLOWS_ICONS } from '../../constants/icons'
import { ScanContext } from '../../contexts/ScanContext'
import ScanGrayBox from '../shared/Box/ScanGrayBox'
import Loader from '../shared/Loader'

const SecondLayoutOptions = ({
  switchScanType,
  haveScanned,
  text,
  children,
  loading,
  codeType,
  showInformation,
  setShowInformation
}) => {
  const { setSingleScan, setScanning } = useContext(ScanContext)
  const [ close, setClose ] = useState(true)

  useEffect(() => {
    setSingleScan(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <div className="absolute bottom-0 z-10 flex w-full flex-col rounded-t-3xl bg-grayish-white p-4">
        <Loader />
      </div>
    )
  }

  if (switchScanType && !haveScanned) {
    return (
      <div className="absolute bottom-0 z-10 flex w-full flex-col rounded-t-3xl bg-grayish-white p-4 md:p-6">
        <div
          onClick={() => {
            setScanning(false)
            setClose(!close)
          }}
          role="button"
          tabIndex="0"
          className="mx-4 flex justify-between text-sm font-light text-[color:#6B7F86]"
        >
          {text}
          <img
            src={chevronDownIcon}
            alt="cerrar manual"
            className={`my-auto ml-4 h-2 w-3 ${close && 'rotate-180'}`}
          />
        </div>
        {!close &&
          <ScanGrayBox text="Sin pedidos" icon={SCAN_FLOWS_ICONS.packageGray} />
        }
      </div>
    )
  }

  if (haveScanned || codeType !== 'pinflag') {
    return (
      <div className="absolute bottom-0 z-10 flex w-full flex-col rounded-t-3xl bg-grayish-white p-4">
        <div
          onClick={() => {
            setShowInformation(!showInformation)
          }}
          role="button"
          tabIndex="0"
          className="mx-4 flex justify-between text-sm font-light text-[color:#6B7F86]"
        >
          {text}
          <img
            src={chevronDownIcon}
            alt="cerrar manual"
            className={`my-auto ml-4 h-2 w-3 ${!showInformation && 'rotate-180'}`}
          />
        </div>
        {showInformation && children}
      </div>
    )
  }

  return null
}

export default SecondLayoutOptions
