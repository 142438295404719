import React from 'react'

import { HOME_ICONS } from '../../constants/icons'
import { getTitleAndColor } from '../../helpers/utils'
import Box from '../shared/Box/Box'

import PercentageChart from './PercentageChart'

const CapacityBox = ({title, number, capacity}) => {
  const styleChart = getTitleAndColor(number)

  return (
    <Box>
      <div className="flex justify-around gap-x-5 p-2">
        <div className="flex flex-col justify-around p-2 pl-6 text-left text-xs font-light text-medium-gray md:text-lg">
          <div className="text-lg font-bold text-black">{styleChart.title}</div>
          <div className="mr-3 flex items-center">
            <img className="mr-2" src={HOME_ICONS.inventory[styleChart.color]} alt="inventory"/>
            <div className="mr-1 text-xxs sm:text-xs xs:text-xs">{capacity.capacidadbultos-capacity.packagesCount}</div>
            <div className="text-xxs sm:text-xs xs:text-xs">{title}</div>
          </div>
        </div>
        <div>
          <PercentageChart percentage={number} colour={styleChart.color} fill={styleChart.fill}/>
        </div>
      </div>
    </Box>
  )
}

export default CapacityBox
