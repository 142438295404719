export const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })

export const urlBlobToFile = (blobUrl, filename) => fetch(blobUrl)
  .then(response => response.blob())
  .then(blob => new File([blob], filename))
