import React from 'react'

const cleanPercentage = (percentage) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0
  const isTooHigh = percentage > 100
  // eslint-disable-next-line no-nested-ternary
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage
}

const Circle = ({ colour, percentage }) => {
  const r = 40
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - percentage) * circ) / 100
  return (
    <circle
      r={r}
      cx={50}
      cy={50}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ''}
      strokeWidth=".5rem"
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    />
  )
}

const Text = ({ percentage, fill }) => (
  <text
    x="50%"
    y="50%"
    dominantBaseline="central"
    textAnchor="middle"
    fontSize="1.4em"
    className={`${fill} font-bold`}
  >
    {percentage.toFixed(0)}%
  </text>
)


const PercentageChart = ({ percentage, colour, fill }) => {
  const pct = cleanPercentage(percentage)
  return (
    <svg width={100} height={100}>
      <g transform={`rotate(-90 ${'50 50'})`}>
        <Circle colour="lightgrey" />
        <Circle colour={colour} percentage={pct} />
      </g>
      <Text percentage={pct} fill={fill}/>
    </svg>
  )
}

export default PercentageChart
