import React, { useContext } from 'react'

import { FEEDBACK_ICONS } from '../../constants/icons'
import { ScanContext } from '../../contexts/ScanContext'

const Notification = ({ show, handleClose, children, error }) => {
  const { setScanning } = useContext(ScanContext)

  return (
    <div
      className={`fixed top-14 left-[5%] flex w-[90%] rounded-lg bg-light-gray transition-all ${
        show ? 'top-14 opacity-100' : 'top-0 scale-0 opacity-0'
      } z-20`}
      role="button"
      tabIndex="0"
      onClick={() => {
        handleClose()
        setScanning(false)
      }}
    >
      <div className="m-auto flex px-2">
        <img
          src={error ? FEEDBACK_ICONS.errorIcon : FEEDBACK_ICONS.successIcon}
          alt={error ? 'error' : 'exito'}
          className="m-4 ml-0 w-8"
        />
        <div className="my-auto">{children}</div>
      </div>
    </div>
  )
}

export default Notification
