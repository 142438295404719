import React from 'react'

import { SEARCH_ICONS } from '../../constants/icons'

const SearchBar = ({placeholder, setter, getter, searcher, filter}) => (
  <div className="m-auto w-full max-w-sm">
    <form className="relative flex w-full items-center text-medium-gray focus-within:text-dark-gray" onSubmit={searcher}>
      <img className="absolute ml-3 h-3 w-3" src={SEARCH_ICONS.searchIcon} alt="search"/>
      <div className="absolute right-0 mr-2">{filter}</div>
      <input
        className="h-10 w-full rounded-2xl border-none pr-3 pl-8 text-sm shadow ring-light-gray focus:ring-2 focus:ring-medium-gray"
        value={getter}
        onChange={(e) => setter(e.target.value)}
        placeholder={placeholder}
      />
    </form>
  </div>
)


export default SearchBar
