import React, {  useEffect, useState } from 'react'
import { useRecordWebcam } from 'react-record-webcam'

import { urlBlobToFile } from '../../../utils/files'
import { addIncidentVideo } from '../../../helpers/requests/warehouse'
import Notification from '../../../components/shared/Notification'

import CountupTimer  from './CountupTimer'

const AppVideo = ({
  recordPackage,
  setPackageWeightError,
  setIsRecording,
  isRecording
}) => {
  const {
    activeRecordings,
    cancelRecording,
    clearPreview,
    closeCamera,
    createRecording,
    devicesByType,
    openCamera,
    startRecording,
    stopRecording
  } = useRecordWebcam({fileType: 'mp4'})

  const [error, setError] = useState(false)
  const [timerStart, setTimerStart] = useState(false)
  const [videoDeviceId, setVideoDeviceId] = useState('')
  const [audioDeviceId, setAudioDeviceId] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [currentRecording, setCurrentRecording] = useState('')

  const start = async () => {
    const recording = await createRecording(videoDeviceId, audioDeviceId)
    if (recording) {
      await openCamera(recording.id)
      setCurrentRecording(recording.id)
    }
  }
  const handleStartRecording = (id) => {
    startRecording(id)
    setTimerStart(true)
  }
  const handleStopRecording = (id) => {
    stopRecording(id)
    setTimerStart(false)
  }
  const updateRecordingUrlByPackageId = (packageId, newRecordingUrl) => {
    setPackageWeightError((prevState) => {
        const updatedPackageWeight = prevState.map(packageWeight => {
            if (packageWeight.packageId === packageId) {
                return { ...packageWeight, recordingUrl: newRecordingUrl }
            }
            return packageWeight
        })
        return updatedPackageWeight
    })
  }

  const createBody = async (recording) => {
    const formData = new FormData()
    const link = recording.previewRef.current.src
    const file = await urlBlobToFile(link, 'Video')

    formData.append('files', file)
    formData.append('packageId', recordPackage.packageId)
    formData.append('description', 'El paquete presenta daños')

    return formData
  }

  const uploadVideo = async (recording) => {
    const body = await createBody(recording)
    try {
      const response = await addIncidentVideo(body)
      updateRecordingUrlByPackageId(recordPackage.packageId, response.url)
      setSubmitSuccess(true)
    } catch {
      setError(true)
    }
  }

  const handleSubmitSuccess = async () => {
    setSubmitSuccess(false)
    setIsRecording(false)
    await closeCamera(currentRecording)
    await clearPreview(currentRecording)
  }

  const handleError = async () => {
    setError(false)
    await clearPreview(currentRecording)
  }

  useEffect(() => {
    if (currentRecording !== '' && isRecording) {
      cancelRecording(currentRecording)
    }
    if ( videoDeviceId !=='' && audioDeviceId !=='' && isRecording){
      start()
    }
    if (!isRecording) {
      closeCamera(currentRecording)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoDeviceId, audioDeviceId, isRecording])

  useEffect(() => {
    if ( devicesByType ) {
      setVideoDeviceId(devicesByType.video[0].deviceId)
      setAudioDeviceId(devicesByType.audio[0].deviceId)
    }
  }, [devicesByType])

  return (
    <div className="mx-auto h-full text-black">
      <div className="my-4 grid h-full gap-4">
        {activeRecordings?.map((recording) => (
          <div className="rounded-lg" key={recording.id}>
            <div className="absolute top-[15%] z-20 flex w-full justify-center text-white">
              <CountupTimer
                timerClasses='bg-[#FF6262] rounded-full px-4 py-1'
                start={timerStart}
              />
            </div>
            <div className={`h-full w-full overflow-hidden ${
                recording.previewRef.current?.src.startsWith('blob:')
                  ? 'hidden'
                  : 'visible'
            }`}>
              <video ref={recording.webcamRef} loop autoPlay playsInline muted className='h-full max-h-screen w-full object-cover'>
                <track src="captions_es.vtt" kind="captions" srcLang="es" label="subtítulos_en_español"/>
              </video>
            </div>

            <div className="absolute top-[85%] z-0 flex w-full justify-center space-x-2">
              <button
                type='button'
                className="mt-4"
                onClick={() => recording.status === 'RECORDING' ? handleStopRecording(recording.id) : handleStartRecording(recording.id) }
              >
                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="56" height="56" rx="28" stroke="white" strokeWidth="2"/>
                {recording.status === 'RECORDING' ? (
                  <rect x="17" y="17" width="24" height="24" rx="3" fill="#FF6262"/>
                ) : (
                  <rect x="6" y="6" width="46" height="46" rx="23" fill="white"/>
                )}
                </svg>
              </button>
            </div>

            <div
              className={`h-full ${
                recording.previewRef.current?.src.startsWith('blob:')
                  ? 'visible'
                  : 'hidden'
              }`}
            >
              <video ref={recording.previewRef} autoPlay loop playsInline className='h-full max-h-screen w-full object-cover'>
                <track src="captions_es.vtt" kind="captions" srcLang="es" label="subtítulos_en_español"/>
              </video>
              <div
                className={`absolute top-[85%] z-0 mt-4 flex w-full
                justify-center gap-28  ${
                recording.previewRef.current?.src.startsWith('blob:')
                  ? 'visible'
                  : 'hidden'
              }`}>
                <button
                  type='button'
                  className="mt-4 flex w-28 items-center justify-center gap-2 rounded-full bg-[#1B1B1B]/60 py-2 px-4 text-center text-sm text-white"
                  onClick={() => clearPreview(recording.id)}
                  disabled={submitSuccess}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.9 15.5C4.28333 15.5 2.89583 14.975 1.7375 13.925C0.579167 12.875 0 11.5667 0 10C0 8.43333 0.579167 7.125 1.7375 6.075C2.89583 5.025 4.28333 4.5 5.9 4.5H12.2L9.6 1.9L11 0.5L16 5.5L11 10.5L9.6 9.1L12.2 6.5H5.9C4.85 6.5 3.9375 6.83333 3.1625 7.5C2.3875 8.16667 2 9 2 10C2 11 2.3875 11.8333 3.1625 12.5C3.9375 13.1667 4.85 13.5 5.9 13.5H13V15.5H5.9Z" fill="white"/>
                  </svg>
                  Repetir
                </button>
                <button
                  type='button'
                  className="mt-4 flex w-28 items-center justify-center rounded-full bg-blue py-2 px-4 text-center text-sm text-white"
                  onClick={() => uploadVideo(recording)}
                  disabled={submitSuccess}
                >
                  Subir
                </button>
              </div>
            </div>
          </div>
        ))}
        <Notification show={error} handleClose={handleError} error>
          Algo salió mal. Intenta nuevamente.
        </Notification>
        <Notification show={submitSuccess} handleClose={handleSubmitSuccess}>
          Archivo cargado exitosamente.
        </Notification>
      </div>
    </div>
  )
}

export default AppVideo
