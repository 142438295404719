import React, {useState, useEffect} from 'react'

import Box from '../shared/Box/Box'
import { HOME_ICONS } from '../../constants/icons'

const PackageBoxWeighing = ({
	code,
  packageWeightError,
  setPackageWeightError,
  recordPackage,
  setRecordPackage,
  type
}) => {
  const [isCorrectStyle, setIsCorrectStyle] = useState('bg-[#F1F4F7]')
  const [isWrongStyle, setIsWrongStyle] = useState('bg-[#F1F4F7]')
  const [iconColor, setIconColor] = useState('#1D283A')
  const [isSelected, setIsSelected] = useState(false)

	const handleError = (orderIds) => {
		setIsCorrectStyle('hidden')
		setIsWrongStyle('bg-[#EB5555]/[.19]')
		setIconColor('#EB5555')
		if (!orderIds.includes(code.orderId)) {
			const newList = [...packageWeightError, { orderId: code.orderId, packageId: code.packageId, recordingUrl: '', error: true}]
			setPackageWeightError(newList)
		}
	}

	const handleCorrect = (orderIds) => {
		setIsWrongStyle('hidden')
		setIsCorrectStyle('bg-[#17C23D]/[.19]')
		setIconColor('#17C23D')
		if (!orderIds.includes(code.orderId)) {
			const newList = [...packageWeightError, { orderId: code.orderId, packageId: code.packageId, recordingUrl: 'none', error: false}]
			setPackageWeightError(newList)
		}
	}

	const handleNone = () => {
		setIsWrongStyle('bg-[#F1F4F7]')
		setIsCorrectStyle('bg-[#F1F4F7]')
		setIconColor('#1D283A')
		const newList = packageWeightError.filter(item => item.orderId !== code.orderId)
		setPackageWeightError(newList)
	}


  const handleSelect = (text) => {
    const orderIds = packageWeightError.map(item => item.orderId)
    if (text === 'error') {
      handleError(orderIds)
    } else if (text === 'correct') {
      handleCorrect(orderIds)
    } else if (text === 'none') {
      handleNone()
    }
  }

  const handleRecord = () => {
    setRecordPackage(code)
  }

  useEffect(() => {
    if(recordPackage.orderId === code.orderId) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [code.orderId, recordPackage])

  return (
		<Box>
			<div
				className="flex flex-col justify-center p-2"
				role="button"
				tabIndex={0}
				onClick={(type === 'select') ? handleRecord : undefined }
			>
				<div className="relative flex h-10 w-full justify-center">
					<img src={HOME_ICONS.packageIcon} alt="package"/>
					<input
						name="select"
						type="radio"
						className={`absolute top-0 right-0 ${type === 'compare' ? 'hidden': ''}`}
						onChange={ handleRecord }
						checked={ isSelected }
					/>
					<button
						type="button"
						className='absolute top-0 right-0'
						onClick={()=>{handleSelect('none')}}
					>
						<svg
							width="19"
							height="19"
							viewBox="0 0 19 19"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className={`${type === 'compare' ? '': 'hidden'}`}
						>
							<rect x="11" y="17" width="3" height="3" rx="1.5" transform="rotate(180 11 17)" fill="#1D283A"/>
							<rect x="11" y="11" width="3" height="3" rx="1.5" transform="rotate(180 11 11)" fill="#1D283A"/>
							<rect x="11" y="5" width="3" height="3" rx="1.5" transform="rotate(180 11 5)" fill="#1D283A"/>
						</svg>
					</button>
				</div>
				<span className="mt-2 text-[10px] font-normal text-[#6B7F86]">{code.orderId}</span>
				<div className={`${type === 'compare' ? 'mt-4 flex w-full flex-row gap-4': 'hidden'}`}>
					<button
						type="button"
						className={`flex h-7 flex-1 items-center
						justify-center rounded-lg ${isWrongStyle}`}
						onClick={()=>{handleSelect('error')}}
					>
						<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.4 9.99844L0 8.59844L3.6 4.99844L0 1.42344L1.4 0.0234375L5 3.62344L8.575 0.0234375L9.975 1.42344L6.375 4.99844L9.975 8.59844L8.575 9.99844L5 6.39844L1.4 9.99844Z" fill={iconColor}/>
						</svg>
					</button>
					<button
						type="button"
						className={`flex h-7 flex-1 items-center
						justify-center rounded-lg ${isCorrectStyle}`}
						onClick={()=>{handleSelect('correct')}}
						>
						<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z" fill={iconColor}/>
						</svg>
					</button>
				</div>

			</div>
		</Box>
  )
}

export default PackageBoxWeighing
