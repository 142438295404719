import { React, useContext, useEffect, useState } from 'react'

import PhotoCamera from '../components/PhotoCamera'
import PhotoEvidenceFlow from '../components/Deliver/PhotoEvidenceFlow'
import Header from '../components/Header'
import QrScannerLayout from '../components/QrScanner/QrScannerLayout'
import Notification from '../components/shared/Notification'
import PackageInformation from '../components/shared/PackageInformation'
import { deliverPackage, pickPackage } from '../helpers/requests/statesChanges'
import { getPackagePersonalization } from '../helpers/requests/packagesInformation'
import Loader from '../components/shared/Loader'
import SubmitSuccess from '../components/shared/SubmitSuccess'
import { ScanContext } from '../contexts/ScanContext'
import DeliveredDetails from '../components/Deliver/DeliveredDetails'

const Deliver = () => {
  const [picture, setPicture] = useState(null)
  const { codes, setCodes } = useContext(ScanContext)
  const [packageLocation, setLocation] = useState(null)
  const [takePicture, setTakePicture] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitCodeError, setSubmitCodeError] = useState(false)
  const [submitEvidenceError, setSubmitEvidenceError] = useState(false)
  const [evidencePhoto, setEvidencePhoto] = useState(true)
  const [loading, setLoading] = useState(false)
  const [deliveredDetails, setDeliveredDetails] = useState({})

  useEffect(() => {setCodes([])}, [setCodes])

  const getPersonalization = async (packageId) => {
    try {
      const personalization = await getPackagePersonalization(packageId)
      setEvidencePhoto(personalization.evidencePhoto)
    } catch {
      setEvidencePhoto(true)
    }
  }

  const validateCode = async (textCode) => {
    try {
      const { CODBULTOCLIENTE, CODBULTOGENVISIBLE, location, IDBULTO } = await pickPackage(textCode)

      await getPersonalization(IDBULTO)

      setCodes([{ pinflagId: CODBULTOGENVISIBLE, orderId: CODBULTOCLIENTE }])
      setLocation(location)
    } catch(error) {
      setSubmitCodeError(true)
    }
  }

  const delivePackage = async () => {
    setLoading(true)

    const body = evidencePhoto
      ? {
          type: 'img/jpg',
          base64: picture
        }
      : {}

    try {
      const response = await deliverPackage(codes[0].pinflagId, body)
      setDeliveredDetails(response)
      setSubmitSuccess(true)
    } catch(error) {
      setSubmitEvidenceError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {takePicture ? (
        <>
          <Header to="/" name="Entregar" color="bg-orange" qrScannerStyle />
          <PhotoCamera picture={picture} setPicture={setPicture}>
            <PhotoEvidenceFlow
              submitSuccess={submitSuccess}
              loading={loading}
              picture={picture}
              setPicture={setPicture}
              sendPhotoEvidence={delivePackage}
              deliveredDetails={deliveredDetails}
            />
          </PhotoCamera>
          <Notification
            show={submitEvidenceError}
            handleClose={() => setSubmitEvidenceError(false)}
            error
          >
            La imagen no ha podido ser enviada
          </Notification>
        </>
      ) : (
        <>
          <QrScannerLayout
            name="Entregar"
            color="border-orange"
            backgroundColor="bg-orange"
            validateCode={validateCode}
            codeType="pinflag"
            haveScanned={codes.length > 0}
          >
            {loading && <Loader />}
            {!loading && !submitSuccess && (
              <PackageInformation
                title="Pedido identificado"
                infoText={`Ve a buscarlo al rack, y entregaselo al cliente${
                  evidencePhoto
                    ? ', para finalizar la entrega deberás tomar una foto de evidencia'
                    : '.'
                }`}
                packageBoxTitle="Orden de venta"
                packageBoxBody={codes[0]?.orderId}
                location={packageLocation}
                firstButton={
                  evidencePhoto
                    ? { text: 'Tomar Foto', onClick: () => setTakePicture(true) }
                    : { text: 'Finalizar', onClick: () => delivePackage() }
                }
              />
            )}
            {submitSuccess && (
              <>
                <SubmitSuccess text="Pedido Entregado" />
                {deliveredDetails && <DeliveredDetails packageDetails={deliveredDetails} />}
              </>
            )}
          </QrScannerLayout>
          <Notification show={submitCodeError} handleClose={() => setSubmitCodeError(false)} error>
            El código ingresado es inválido
          </Notification>
        </>
      )}
    </div>
  )
}

export default Deliver
