import apiClient from '../../axiosConfig/apiClient'

export const addIncidentVideo = async (formData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }
  const response = await apiClient.post('/warehouse/unboxing-incident', formData, config)

  return response.data
}
