import { React, useState } from 'react'
import RICIBs from 'react-individual-character-input-boxes'

import { chevronDownIcon, padLockIcon } from '../../constants/icons'
import Button from '../shared/Button'

const TypeCode = ({ handleCode, setManually, codeType }) => {
  const [manualCode, setManualCode] = useState('')
  const boxStyle =
    'my-8 mx-0 w-10 h-10 text-lg border-2 rounded-lg border-white bg-light-blue focus:border-blue focus:bg-white focus:outline-none xs:mx-0.5'
  const boxStyleEnd =
    'my-8 ml-0 mr-1 w-10 h-10 text-lg border-2 rounded-lg border-white bg-light-blue focus:border-blue focus:bg-white focus:outline-none xs:mr-2'

  const continueCondition =
    (codeType === 'pinflag' && manualCode.length > 0) || manualCode.length === 7

  return (
    <div className="absolute bottom-0 z-20 w-full rounded-t-xl bg-white pb-5 xs:pb-20">
      <div className="mx-auto mt-4 mb-8 flex justify-around text-lg text-blue">
        Digita el código {codeType === 'pinflag' ? 'del Pedido' : 'de la ubicación'}
        <span onClick={() => setManually(false)} role="button" tabIndex="0" className="my-auto">
          <img src={chevronDownIcon} alt="cerrar manual" className="h-2 w-3" />
        </span>
      </div>
      <img src={padLockIcon} alt="candado" className="mx-auto my-2 w-5" />
      {codeType === 'pinflag' && (
        <div className="my-4 mx-10 text-sm text-medium-gray xs:mx-16">
          Digita el código Pinflag bajo el Código Qr o la Orden de Venta
        </div>
      )}
      {codeType === 'pinflag' ? (
        <input
          onChange={(event) => setManualCode(event.target.value)}
          className="mt-4 mb-8 h-10 rounded-lg border-2 border-light-blue text-lg focus:border-blue focus:outline-none"
        />
      ) : (
        <RICIBs
          amount={7}
          handleOutputString={(code) => setManualCode(code)}
          inputProps={[
            { className: boxStyle },
            { className: boxStyle },
            { className: boxStyleEnd },
            { className: boxStyle },
            { className: boxStyleEnd },
            { className: boxStyle },
            { className: boxStyle }
          ]}
          autoFocus
        />
      )}
      <div className="mx-8 md:m-auto md:max-w-md">
        <Button
          color={continueCondition ? 'bg-blue' : 'bg-light-blue'}
          textColor={continueCondition ? 'text-white' : 'text-medium-gray'}
          onClick={
            continueCondition
              ? () => {
                  setManually(false)
                  handleCode(manualCode)
                }
              : undefined
          }
        >
          Continuar
        </Button>
      </div>
    </div>
  )
}

export default TypeCode
