import React from 'react'

import { HOME_ICONS } from '../../constants/icons'

import CapacityBox from './CapacityBox'
import InformationBox from './InformationBox'
import {
  PACKAGE_BOUGHT,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PICKED_UP,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_STORED
} from '../../constants/packageStatus'

const PointStats = ({ pointInfo, error }) => {
  if (error) {
    return (
      <div className="flex h-full w-full flex-col justify-center bg-light-gray text-center">
        <div className="mt-24">
          Ocurrió un error obteniendo las estadísticas. Inténtelo de nuevo más tarde o contacte al
          soporte Pinflag.
        </div>
      </div>
    )
  }
  return (
    <div className="mt-5 flex flex-col lg:flex-row">
      <div className="flex flex-col px-3">
        <p className="self-start py-2 px-3 text-xs font-light text-medium-gray sm:text-sm">
          Pedidos
        </p>
        <div className="grid grid-cols-3 gap-4">
          <InformationBox
            title="Almacenados"
            number={pointInfo.totalPackagesStored}
            image={HOME_ICONS.packageIcon}
            status={[PACKAGE_STORED, PACKAGE_PICKED_UP].join(',')}
          />
          <InformationBox
            title="Por Almacenar"
            number={pointInfo.totalPackagesPendingStorage}
            image={HOME_ICONS.receivedIcon}
            status={PACKAGE_RECEIVED}
          />
          <InformationBox
            title="Por Recibir"
            number={pointInfo.totalPackagesToReceive}
            image={HOME_ICONS.truckIcon}
            status={[
              PACKAGE_BOUGHT,
              PACKAGE_PROCESSED,
              PACKAGE_IN_TRANSIT,
              PACKAGE_ON_DELIVERY
            ].join(',')}
          />
        </div>
      </div>
      <div className="m-3 flex flex-col lg:mt-0">
        <p className="self-start py-2 px-3 text-xs font-light text-medium-gray sm:text-sm">
          Capacidad
        </p>
        <CapacityBox
          title="Espacios disponibles"
          number={pointInfo.capacityPercentage}
          capacity={{
            packagesCount: pointInfo.warehouseInfo.packagesCount,
            capacidadbultos: pointInfo.warehouseInfo.capacidadbultos
          }}
        />
      </div>
    </div>
  )
}

export default PointStats
