import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { ClaimContext } from '../../contexts/ClaimContext'
import ROUTES from '../../constants/routes'
import { SCAN_FLOWS_ICONS, whiteArrow } from '../../constants/icons'
import Button from '../../components/shared/Button'
import ProductSelect from '../../components/ProductSelect'
import { ScanContext } from '../../contexts/ScanContext'

const ClaimPackage = () => {
  const { codes, products } = useContext(ClaimContext)
  const { setCodes, setSingleScan } = useContext(ScanContext)
  const [productsList, setProductsList] = useState([])
  const [productChecks, setProductChecks] = useState([])
  const [packageReady, setPackageReady] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (products.length === 0) navigate(`${ROUTES.PACKAGES}/${codes.pinflagId}`)
    const productsItems = products.reduce(
      (list, product) => [...list, ...new Array(product.quantity).fill(product)],
      []
    )
    setProductChecks(new Array(productsItems.length).fill(false))
    setProductsList(productsItems)
  }, [navigate, codes, products])

  const checkProductItem = (index) => {
    const newChecks = [...productChecks]
    newChecks[index] = !newChecks[index]
    setProductChecks(newChecks)
  }

  const sendToStore = () => {
    setCodes([codes])
    setSingleScan(true)
    navigate(`${ROUTES.STORAGE}?storePickUp=true`)
  }

  return (
    <div className="flex h-full w-full flex-col justify-center bg-pinflag-dark text-center">
      {packageReady ? (
        <div className="flex h-full w-full flex-col px-5 pb-5">
          <div className="flex px-2 py-3 text-white">
            <div className="mx-auto mt-4 text-xl font-semibold">Identificar</div>
          </div>
          <div className="mx-3 my-auto text-white">
            <img
              src={SCAN_FLOWS_ICONS.claimPackage}
              alt="Registra el pedido"
              className="mx-auto mb-4 w-16"
            />
            <div className=" text-xl">Registra el pedido</div>
            <div className="mt-3 text-lg font-bold">Pinflag Id: {codes.pinflagId}</div>
            <div className="mb-3 text-lg font-bold">O. venta: {codes.orderId}</div>
            Registra el código en la bolsa para que el pedido pueda ser
            identificado más tarde.
          </div>
          <Button
            color="bg-blue"
            textColor="text-white"
            shadow="drop-shadow-blue"
            onClick={sendToStore}
          >
            Almacenar
          </Button>
        </div>
      ) : (
        <>
          <div className="flex px-2 py-3 text-white">
            <Link to={`${ROUTES.PACKAGES}/${codes.pinflagId}`} className="p-4">
              <img className="w-6" src={whiteArrow} alt="Volver" />
            </Link>
            <div className="mx-auto mt-4 pr-6 text-xl font-semibold">Recolectar</div>
          </div>
          <div className="mx-3 my-auto text-white">
            <div className="mb-3 text-xl">Prepara el pedido</div>
            Guarda{' '}
            {productsList.length === 1
              ? 'el producto'
              : `los ${productsList.length} productos`}{' '}
            dentro de una bolsa.
          </div>
          <div className="rounded-t-3xl bg-grayish-white p-6">
            <div className="text-left font-light text-medium-gray">Recolectar pedido</div>
            <div className="my-4 max-h-1/2-screen overflow-y-auto">
              {productsList.map((product, index) => (
                <ProductSelect
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  product={product}
                  selected={productChecks[index]}
                  setSelected={() => checkProductItem(index)}
                />
              ))}
            </div>
            <Button
              color="bg-blue"
              textColor="text-white"
              shadow="drop-shadow-blue"
              inactive={productChecks.some((check) => !check)}
              onClick={() => setPackageReady(true)}
            >
              Listo
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default ClaimPackage
