import React, { useContext } from 'react'

import { chevronDownIcon, SCAN_FLOWS_ICONS } from '../../constants/icons'
import { ScanContext } from '../../contexts/ScanContext'
import ScanGrayBox from '../shared/Box/ScanGrayBox'
import Loader from '../shared/Loader'

const LayoutOptions = ({
  switchScanType,
  haveScanned,
  name,
  children,
  loading,
  codeType,
  showInformation,
  setShowInformation
}) => {
  const { singleScan, setSingleScan, setScanning } = useContext(ScanContext)

  if (loading) {
    return (
      <div className="absolute bottom-0 z-10 flex w-full flex-col rounded-t-3xl bg-grayish-white p-4">
        <Loader />
      </div>
    )
  }

  if (switchScanType && !haveScanned) {
    return (
      <div className="absolute bottom-0 z-10 flex w-full flex-col rounded-t-3xl bg-grayish-white p-4 md:p-6">
        <div
          onClick={() => {
            setScanning(false)
            setSingleScan(!singleScan)
          }}
          role="button"
          tabIndex="0"
          className="m-auto flex font-light"
        >
          {name} en grupo
          <img
            src={chevronDownIcon}
            alt="cerrar manual"
            className={`my-auto ml-4 h-2 w-3 ${singleScan && 'rotate-180'}`}
          />
        </div>
        {!singleScan && <ScanGrayBox text="Sin pedidos" icon={SCAN_FLOWS_ICONS.packageGray} />}
      </div>
    )
  }

  if (haveScanned || codeType !== 'pinflag') {
    return (
      <div className="absolute bottom-0 z-10 flex w-full flex-col rounded-t-3xl bg-grayish-white p-4">
        <div
          onClick={() => {
            setShowInformation(!showInformation)
          }}
          role="button"
          tabIndex="0"
          className="m-auto flex font-light"
        >
          Información escaneo
          <img
            src={chevronDownIcon}
            alt="cerrar manual"
            className={`my-auto ml-4 h-2 w-3 ${!showInformation && 'rotate-180'}`}
          />
        </div>
        {showInformation && children}
      </div>
    )
  }

  return null
}

export default LayoutOptions
