import React from 'react'
import { useNavigate } from 'react-router-dom'

import { whiteArrow } from '../constants/icons'

import ConditionalLink from './shared/ConditionalLink'

const Header = ({ name, to, qrScannerStyle, color = 'bg-pinflag-dark' }) => {
  const navigate = useNavigate()

  return (
    <div
      className={`
    fixed ${color} flex rounded-b-3xl px-2 text-white ${
        qrScannerStyle
          ? 'z-20 mb-5 w-full justify-items-start py-1 xs:py-2'
          : 'top-0 w-screen flex-col items-center gap-x-4 py-3 lg:h-36 lg:py-4 lg:pt-20'
      }`}
    >
      <div className="flex w-full justify-items-start">
        {(typeof to === 'function') ? (
            <button onClick={to} type="button" className="p-4">
              <img className="w-6" src={whiteArrow} alt="Volver" />
            </button>
        ): (
          <ConditionalLink to={to} condition={to !== undefined}>
            <button onClick={to ? undefined : () => navigate(-1)} type="button" className="p-4">
              <img className="w-6" src={whiteArrow} alt="Volver" />
            </button>
          </ConditionalLink>
        )}
        <div className="mx-auto mt-4 pr-6 text-xl font-semibold">{name}</div>
      </div>
    </div>
  )
}

export default Header
