import React, { useState } from 'react'

import { PACKAGE_STATUS_FILTER } from '../../constants/packageStatus'

const PackagesFilter = ({ show, handleClose, filterPackages }) => {
  const [filters, setFilters] = useState([])
  const clickOutside = (e) => {
    if (e.currentTarget !== e.target) return
    handleClose()
  }

  if (!show) return <div />

  return (
    <div
      className="fixed top-40 left-0 z-20 h-full w-full bg-transparent-black"
      onClick={clickOutside}
      role="button"
      tabIndex="0"
    >
      <div className="h-fit rounded-b-2xl bg-white px-10 pb-4">
        <div className="flex justify-between">
          <button
            className="text-purple"
            type="button"
            onClick={() => {
              filterPackages(filters)
              handleClose()
            }}
          >
            Filtrar Pedidos
          </button>
          <button
            type="button"
            onClick={() => {
              setFilters([])
              filterPackages([])
              handleClose()
            }}
          >
            Limpiar
          </button>
        </div>
        <div className="my-4 text-left text-sm text-medium-gray">Estado</div>
        <div className="flex flex-wrap gap-2">
          {PACKAGE_STATUS_FILTER.map((status, index) => (
            <button
              key={status.name}
              className={`rounded-full px-3 py-1 text-sm ${
                filters.includes(index) ? 'bg-light-purple text-purple' : ''
              }`}
              onClick={() =>
                setFilters((prev) => {
                  if (filters.includes(index)) {
                    return prev.filter((filter) => filter !== index)
                  }
                  return [...prev, index]
                })
              }
              type="button"
            >
              {status.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PackagesFilter
