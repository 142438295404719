import apiClient from '../../axiosConfig/apiClient'
import { DELIVER_PACKAGE, INGRESS_PACKAGE, PICK_PACKAGE, STORE_PACKAGE } from '../../constants/api'

export const ingressPackage = async (packageId) => {
  const response = await apiClient.put(INGRESS_PACKAGE(packageId))

  const { CODBULTOGENVISIBLE, CODBULTOCLIENTE, packageStatus, location } = response.data

  return {
    packageCodes: { pinflagId: CODBULTOGENVISIBLE, orderId: CODBULTOCLIENTE },
    packageStatus,
    location
  }
}

export const storePackage = (body) => apiClient.put(STORE_PACKAGE, body)

export const pickPackage = async (packageId) => {
  const response = await apiClient.put(PICK_PACKAGE(packageId))

  return response.data
}

export const deliverPackage = async (packageId, body) => {
  const response = await apiClient.put(DELIVER_PACKAGE(packageId), body)

  return response.data
}
