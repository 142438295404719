import React, { useCallback, useState } from 'react'

import BottomNavbar from '../../components/BottomNavbar'
import Header from '../../components/Header'
import Loader from '../../components/shared/Loader'
import PackageList from '../../components/shared/PackageList'
import { getPackagesByStatus } from '../../helpers/requests/packagesInformation'
import useFetch from '../../hooks/useFetch'
import { PACKAGE_BOUGHT } from '../../constants/packageStatus'

const ClaimList = () => {
  const [packagesToClaim, setPackagesToClaim] = useState([])

  const setPackages = useCallback((data) => {
    const ownPackages = data.filter(item => item.deliveryType === 'storePickup')
    setPackagesToClaim(ownPackages)
  }, [])

  const statusToShow = PACKAGE_BOUGHT
  const { isLoading, error } = useFetch(getPackagesByStatus, setPackages, statusToShow)

  const displayPackages = () => {
    switch (true) {
      case isLoading:
        return <Loader />
      case error:
        return <div>Ha ocurrido un error. Inténtelo de nuevo más tarde.</div>
      case packagesToClaim.length === 0:
        return <div>No hay pedidos por reclamar actualmente.</div>
      default:
        return (
          <>
            <div className="my-5 text-left font-semibold">Pedidos por reclamar</div>
            <PackageList packagesInfo={packagesToClaim} statusIcon statusText detail />
          </>
        )
    }
  }

  return (
    <>
      <div className="flex w-screen flex-col items-center justify-center">
        <Header to="/" name="Reclamar" />
        <div className="my-20 flex h-screen w-full max-w-4xl flex-col gap-y-5 overflow-y-auto p-10 lg:mt-48 lg:mb-10">
          {displayPackages()}
        </div>
      </div>
      <BottomNavbar />
    </>
  )
}

export default ClaimList
