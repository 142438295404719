import React, { createContext, useState, useMemo } from 'react'

export const ScanContext = createContext()

const ScanProvider = ({ children }) => {
  const [codes, setCodes] = useState([])
  const [singleScan, setSingleScan] = useState(true)
  const [scanning, setScanning] = useState(true)

  const scanState = useMemo(
    () => ({ codes, setCodes, singleScan, setSingleScan, scanning, setScanning }),
    [codes, scanning, singleScan]
  )

  return <ScanContext.Provider value={scanState}>{children}</ScanContext.Provider>
}

export default ScanProvider
