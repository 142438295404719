import React, { useCallback, useState } from 'react'

import { NOTIFICATION_ICONS } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'
import { getPackagesByStatus } from '../../../helpers/requests/packagesInformation'
import useFetch from '../../../hooks/useFetch'
import Loader from '../../shared/Loader'
import { PACKAGE_BOUGHT } from '../../../constants/packageStatus'

import NotificationCard from './NotificationCard'

const Notifications = () => {
  const [packagesToClaim, setPackagesToClaim] = useState([])

  const setPackages = useCallback((data) => {
    const ownPackages = data.filter((item) => item.deliveryType === 'storePickup')
    setPackagesToClaim(ownPackages)
  }, [])

  const statusToShow = PACKAGE_BOUGHT
  const { isLoading } = useFetch(getPackagesByStatus, setPackages, statusToShow)

  if (isLoading) {
    return (
      <div className="mt-10">
        <Loader />
      </div>
    )
  }

  if (packagesToClaim.length === 0) return <div />

  return (
    <div className="mt-10 flex max-w-[90%] gap-x-4">
      <NotificationCard
        icon={NOTIFICATION_ICONS.claimNotification}
        title="Pedido por reclamar"
        subtitle="Prepara el pedido y almacénalo"
        buttonText="Ver"
        route={
          packagesToClaim.length === 1
            ? `${ROUTES.PACKAGES}/${packagesToClaim[0].packageId}`
            : ROUTES.CLAIM
        }
        number={packagesToClaim.length}
      />
    </div>
  )
}

export default Notifications
