import React, { createContext, useState, useMemo } from 'react'

export const ClaimContext = createContext()

const ClaimProvider = ({ children }) => {
  const [codes, setCodes] = useState({})
  const [products, setProducts] = useState([])

  const claimState = useMemo(
    () => ({ codes, setCodes, products, setProducts }),
    [codes, products]
  )

  return <ClaimContext.Provider value={claimState}>{children}</ClaimContext.Provider>
}

export default ClaimProvider
