import React, { useState } from 'react'

import Modal from '../shared/Modal/Modal'
import Button from '../shared/Button'
import { FEEDBACK_ICONS } from '../../constants/icons'
import { getFormattedDate } from '../../utils/strings'

const DeliveredDetails = ({ packageDetails }) => {
  const [openDetails, setOpenDetails] = useState(false)

  return (
    <>
      <Button textColor="text-text-gray mt-[-2rem] mb-8" onClick={() => setOpenDetails(true)}>
        Ver detalles de entrega
      </Button>
      <Modal show={openDetails} handleClose={() => setOpenDetails(false)}>
        <div className="flex flex-col items-center justify-center">
          <img className="w-8" src={FEEDBACK_ICONS.successBackground} alt="box" />
          <div className="my-2 text-xl font-bold">Pedido entregado</div>
          <div className="font-light text-medium-gray">
            {getFormattedDate(packageDetails.deliveredDate || new Date())}
          </div>
          <div className="mt-2 grid grid-cols-2 gap-4 rounded-xl bg-light-gray px-2 py-4">
            <div>
              <div className="text-sm font-light text-medium-gray">Orden de venta:</div>
              {packageDetails.orderId}
            </div>
            <div>
              <div className="text-sm font-light text-medium-gray">Código Pinflag:</div>
              {packageDetails.pinflagId}
            </div>
            <div>
              <div className="text-sm font-light text-medium-gray">Fecha compra:</div>
              {getFormattedDate(packageDetails.purchaseDate)}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )}

export default DeliveredDetails
