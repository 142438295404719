import { React } from 'react'
import { Link } from 'react-router-dom'

import ROUTES from '../constants/routes'
import { NAV_ICONS } from '../constants/icons'

const BottomNavbar = () => (
    <div className="fixed inset-x-0 bottom-0 z-10 block h-20 bg-white text-xxs text-medium-gray lg:top-0 lg:bg-pinflag-dark xs:text-xs">
      <div className="flex h-full items-center justify-around px-2">
        <Link to={ROUTES.INGRESS} className="flex flex-col items-center gap-y-1 pt-2 pb-1 text-center hover:opacity-75">
          <img src={NAV_ICONS.entry} alt="ingreso"/>
          <span className="block">Ingreso</span>
        </Link>
        <Link to={ROUTES.IDENTIFY_PACKAGE} className="flex flex-col items-center gap-y-1 pt-2 pb-1 text-center hover:opacity-75">
          <img src={NAV_ICONS.store} alt="almacenar"/>
          <span className="block">Almacenar</span>
        </Link>
        <Link to={ROUTES.DELIVER} className="flex flex-col items-center gap-y-1 pt-2 pb-1 text-center hover:opacity-75">
          <img src={NAV_ICONS.deliver} alt="entregar"/>
          <span className="block">Entregar</span>
        </Link>
        <Link to={ROUTES.CLAIM} className="flex flex-col items-center gap-y-1 pt-2 pb-1 text-center hover:opacity-75">
          <img  src={NAV_ICONS.claim} alt="reclamar"/>
          <span className="block">Reclamar</span>
        </Link>
        <Link to={ROUTES.PACKAGES} className="flex flex-col items-center gap-y-1 pt-2 pb-1 text-center hover:opacity-75">
          <img src={NAV_ICONS.packages} alt="pedidos"/>
          <span className="block">Pedidos</span>
        </Link>
      </div>
    </div>
  )

export default BottomNavbar
