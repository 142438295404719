export const API_URL = process.env.REACT_APP_API_URL

export const PINFLAG_BOT_URL = process.env.REACT_APP_PINFLAG_BOT_URL

export const POINT_STATS = '/warehouses/stats'

export const PACKAGES_BY_STATUS = (status) => `/warehouse/packages?status=${status}`

export const INGRESS_PACKAGE = (packageId) =>
  `/warehouse/inputs/companies/packages/${packageId}/reception/confirm`

export const SALE_URL = (packageId) => `/warehouse/package/${packageId}`

export const STORE_PACKAGE = '/warehouse/store-packages'

export const PICK_PACKAGE = (packageId) =>
  `/warehouse/outputs/clients/packages/${packageId}/generate/code`

export const DELIVER_PACKAGE = (packageId) =>
  `warehouse/outputs/clients/packages/${packageId}/evidence`

export const PACKAGE_PERSONALIZATION = (packageId) =>
  `warehouse/${packageId}/personalization`
