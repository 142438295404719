import React, { useContext } from 'react'

import { SCAN_FLOWS_ICONS } from '../constants/icons'
import { ScanContext } from '../contexts/ScanContext'

import ScanGrayBox from './shared/Box/ScanGrayBox'
import ScanInformationBox from './shared/Box/ScanInformationBox'
import Loader from './shared/Loader'
import PackageInformation from './shared/PackageInformation'
import SubmitSuccess from './shared/SubmitSuccess'

const StoreFlow = ({ loading, location, setLocation, submitSuccess, codes, storePackages }) => {
  const { singleScan, setScanning } = useContext(ScanContext)

  const handleCancel = () => {
    setLocation(null)
    setScanning(false)
  }

  if (loading) {
    return <Loader />
  }

  if (submitSuccess) {
    return <SubmitSuccess text={`Pedido${singleScan ? '' : 's'} Almacenado${singleScan ? '' : 's'}`} />
  }

  if (location !== null) {
    return (
      <PackageInformation
        title="Ubicación identificada"
        infoText={`Ahora deja el ${
          singleScan ? 'pedido' : 'grupo de pedidos'
        } en el rack y presiona Almacenar
          para finalizar.`}
        packageBoxTitle={singleScan ? 'Orden de venta' : 'Grupo'}
        packageBoxBody={
          singleScan
            ? codes[codes.length - 1]?.orderId
            : `${codes?.length} Pedido${codes.length > 1 ? 's' : ''}`
        }
        location={location}
        firstButton={{ text: 'Almacenar', onClick: storePackages }}
        secondButton={{ text: 'Cancelar', onClick: handleCancel }}
      />
    )
  }

  return (
    <>
      <ScanInformationBox
        icon={SCAN_FLOWS_ICONS.packageBlue}
        title={singleScan ? 'Orden de venta' : 'Grupo'}
        body={
          singleScan
            ? codes[codes.length - 1]?.orderId
            : `${codes.length} Pedido${codes?.length > 1 ? 's' : ''}`
        }
      />
      <ScanGrayBox text="Escanea la ubicación" icon={SCAN_FLOWS_ICONS.qrCodeGray} />
    </>
  )
}

export default StoreFlow
