import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { SCAN_FLOWS_ICONS, whiteArrow } from '../constants/icons'
import ScanInformationBox from './shared/Box/ScanInformationBox'
import { ScanContext } from '../contexts/ScanContext'
import ROUTES from '../constants/routes'

import PackageInformation from './shared/PackageInformation'

const WeighingFlow = ({
	packageAlreadyStored,
	setPackageAlreadyStored,
	textButton,
  linkButton
}) => {
  const { codes, setCodes } = useContext(ScanContext)
  const navigate = useNavigate()

  if (Object.keys(packageAlreadyStored).length > 0) {
    return (
    <PackageInformation
      title="Pedido Almacenado"
      infoText="Has identificado un pedido que ya ha sido almacenado, puedes cambiar la ubicación si lo deseas."
      packageBoxTitle="Orden de venta"
      packageBoxBody={packageAlreadyStored?.packageCodes?.orderId}
      location={packageAlreadyStored?.location}
      firstButton={{ text: 'Cambiar Ubicación', onClick: () => {
        setCodes([packageAlreadyStored?.packageCodes])
        navigate(ROUTES.STORAGE)
        }
      }}
      secondButton={{
        text: 'Cancelar',
        onClick: () => setPackageAlreadyStored({})
      }}
    />)
  }

  return (
    <>
      <ScanInformationBox
        icon={SCAN_FLOWS_ICONS.packageBlue}
        title="Grupo"
        body={`${codes.length} Pedido${codes.length > 1 ? 's' : ''}`}
      />
      <div className="mx-2 my-0.5 flex">
				<Link
					to={linkButton}
					className="mt-4 flex w-full flex-row items-center justify-center rounded-full bg-[#0F77F0] py-1 text-center text-[17px] text-white"
				>
					{textButton}
					<img className="ml-2 h-[20px] rotate-180" src={whiteArrow} alt="Volver" />
				</Link>
      </div>
    </>
  )
}

export default WeighingFlow
