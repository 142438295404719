import React from 'react'

const ProductSelect = ({ product, selected, setSelected }) => (
  <div
    className={`m-2 grid grid-cols-4 gap-4 rounded-2xl border-2 bg-light-gray py-5 px-1 ${
      selected ? 'border-green' : 'border-grayish-white'
    }`}
    onClick={setSelected}
    role="button"
    tabIndex="0"
  >
    <div className="self-center">
      {product.imageUrl ? (
        <img
          src={product.imageUrl}
          alt="producto"
          className="m-auto h-auto max-h-16 w-auto max-w-[4rem]"
        />
      ) : (
        <div className="m-auto h-16 w-full max-w-[4rem] rounded-lg bg-grayish-white" />
      )}
    </div>
    <div className="col-span-2 flex flex-col justify-start gap-y-1 text-start">
      <div className="text-sm sm:text-base">{product.name}</div>
      <div>
        <div className="text-sm font-light text-medium-gray sm:text-base">SKU</div>
        <div className="text-sm sm:text-base">{product.sku}</div>
      </div>
    </div>
    <div
      className={`m-auto rounded-full border p-1 ${
        selected ? 'border-green' : 'border-medium-gray'
      }`}
    >
      <div className={`rounded p-1 ${selected && 'bg-green'}`} />
    </div>
  </div>
)

export default ProductSelect
