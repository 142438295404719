import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import DetailBox from '../../components/shared/Box/DetailBox'
import ProductsBox from '../../components/shared/Box/ProductsBox'
import BottomNavbar from '../../components/BottomNavbar'
import { getPackageInformation } from '../../helpers/requests/packagesInformation'
import { getFormattedDate } from '../../utils/strings'
import useFetch from '../../hooks/useFetch'
import Loader from '../../components/shared/Loader'
import PACKAGE_STATUS, { PACKAGE_BOUGHT } from '../../constants/packageStatus'
import { locationFormat } from '../../helpers/storage'
import Header from '../../components/Header'
import Button from '../../components/shared/Button'
import { ClaimContext } from '../../contexts/ClaimContext'
import ROUTES from '../../constants/routes'

const PackageDetail = () => {
  const { packageId } = useParams()
  const [infoPackage, setInfoPackage] = useState({})
  const { setCodes, setProducts } = useContext(ClaimContext)
  const navigate = useNavigate()

  const { isLoading, error } = useFetch(getPackageInformation, setInfoPackage, packageId)

  const claimPackage = () => {
    setCodes({ pinflagId: infoPackage.pinflagId, orderId: infoPackage.orderId })
    setProducts(infoPackage.products)
    navigate(ROUTES.CLAIM_PACKAGE)
  }

  const detailInfo = () => {
    switch (true) {
      case isLoading:
        return (
          <div className="h-screen">
            <Loader />
          </div>
        )
      case error:
        return (
          <div className="my-8 text-red">Ha ocurrido un error. Inténtelo de nuevo más tarde.</div>
        )
      default:
        return (
          <>
            <div>
              <div className="self-start pl-1 pb-1 text-left text-xs text-medium-gray sm:text-sm md:text-lg">
                Pedido
              </div>
              <DetailBox
                firstTitle="Código Pinflag"
                firstInfo={infoPackage.pinflagId}
                secondTitle="Estado"
                secondInfo={PACKAGE_STATUS[infoPackage.packageStatus].name}
                thirdTitle={infoPackage.location ? 'Ubicacion' : undefined}
                thirdInfo={locationFormat(infoPackage.location)}
              />
            </div>
            <div>
              <div className="self-start pl-1 pb-1 text-left text-xs text-medium-gray sm:text-sm md:text-lg">
                Venta
              </div>
              <DetailBox
                firstTitle="Orden de Venta"
                firstInfo={infoPackage.orderId}
                secondTitle="Fecha de Compra"
                secondInfo={getFormattedDate(infoPackage.createdAt)}
              />
            </div>
            <div>
              <div className="self-start pl-1 pb-1 text-left text-xs text-medium-gray sm:text-sm md:text-lg">
                Cliente
              </div>
              <DetailBox
                firstTitle="Nombre"
                firstInfo={
                  `${infoPackage.customer?.nombrepersona} ${infoPackage.customer?.apellidopersona}`
                }
              />
            </div>
            {
              (infoPackage.packageStatus === PACKAGE_BOUGHT && infoPackage.deliveryType === 'storePickup') && (
                <>
                <div>
                  <div className="self-start pl-1 pb-1 text-left text-xs text-medium-gray sm:text-sm md:text-lg">
                    Artículos
                  </div>
                  <ProductsBox products={infoPackage.products} />
                </div>
                <Button color="bg-blue" textColor="text-white" shadow="drop-shadow-blue" onClick={claimPackage}>
                  Reclamar
                </Button>
                </>
              )
            }
          </>
        )
    }
  }

  return (
    <div className="h-max w-full flex-col justify-center bg-light-gray text-center">
      <Header name="Detalle Pedido" to={infoPackage.packageStatus === 1 ? ROUTES.CLAIM : ROUTES.PACKAGES}/>
      <div className="mx-auto my-32 flex w-fit flex-col gap-y-5 px-1  lg:mt-44">{detailInfo()}</div>
      <BottomNavbar />
    </div>
  )
}

export default PackageDetail
