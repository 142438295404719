import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ScanContext } from '../../contexts/ScanContext'
import WeighingRecord from './WeighingRecord'
import Header from '../../components/Header'
import PackageBoxWeighing from '../../components/Distribution/PackageBoxWeighing'
import BottomModal from '../../components/shared/Modal/BottomModal'
import { DISTRIBUTION_TEXT } from '../../constants/viewsTexts'

const Weighing = () => {
  const navigate = useNavigate()
  const { codes } = useContext(ScanContext)
  const [showModal, setShowModal] =useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [recordPackage, setRecordPackage] = useState('')
  const [textButton, setTextButton] = useState('Unboxing')
  const [packageWeight, setPackageWeight] = useState(true)
  const [packageToRecord, setPackageToRecord] = useState([])
  const [packageWeightError, setPackageWeightError] = useState([])
  const [textView, setTextView] = useState(DISTRIBUTION_TEXT.CHECK_WEIGHT)
  const [packageWeightErrorLength, setPackageWeightErrorLength] = useState(0)

  const handleCloseModal = () => {
    setShowModal(false)
    navigate('/')
  }

  const handleReturn = () => {
    if (isRecording){
      setIsRecording(false)
    }else {
      setPackageWeight(true)
    }
  }

  const handleClick = () => {
    const withoutErrorLength =  (packageWeightError.filter(item => item.error === false).length)
    if (packageWeight && withoutErrorLength === codes.length){
      setShowModal(true)
    } else if (packageWeight && withoutErrorLength !== codes.length){
      setPackageWeight(false)
    } else if (!packageWeight){
      setIsRecording(true)
    }
  }

  useEffect(() => {
    setPackageToRecord(packageWeightError.filter((packageWeightEmptyUrl) => packageWeightEmptyUrl.recordingUrl === ''))
    const countEmptyRecordingUrl = packageWeightError.filter((packageWeightEmptyUrl) => packageWeightEmptyUrl.recordingUrl === '').length
    const withoutErrorLength =  (packageWeightError.filter(item => item.error === false).length)
    setPackageWeightErrorLength(packageWeightError.filter(item => item.error === true).length)
    if (countEmptyRecordingUrl === 0 && !packageWeight && !isRecording) setShowModal(true)
    if (packageWeight && withoutErrorLength === codes.length){
      setTextButton('Confirmar')
    } else if (packageWeight && withoutErrorLength !== codes.length){
      setTextView(DISTRIBUTION_TEXT.CHECK_WEIGHT)
      setTextButton('Unboxing')
    } else if (!packageWeight){
      setTextView(DISTRIBUTION_TEXT.RECORD_PACKAGE)
      setTextButton('Grabar')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageWeightError, packageWeight, isRecording])

  useEffect(() => {
    if (packageToRecord.length === 0){
      setTextView(DISTRIBUTION_TEXT.NO_PACKAGES)
    }
  }, [packageToRecord])

  return (
    <div className="min-h-screen w-full justify-center bg-light-gray text-center">

      <div className="m-auto w-screen items-center justify-center">
        <Header
          to={packageWeight ? '/distribucion' : handleReturn}
          name="Pesaje"
          color="bg-[#0F77F0]"
          qrScannerStyle
        />
      </div>

      <div
      className={`mx-auto my-20 flex w-full max-w-4xl flex-col gap-y-5
      overflow-y-auto p-10 lg:mt-48 lg:mb-10 ${isRecording ? 'hidden': ''}`}
      >
        <p className="self-center text-[17px] font-medium text-[#1D283A]">
          Paquetes
        </p>
        <p className="self-center text-xs font-light text-medium-gray sm:text-sm">
          {textView}
        </p>

        <div className={`${packageWeight ? 'grid grid-cols-2 gap-4 overflow-auto': 'hidden'}`}>
          {codes.map((code) => (
            <PackageBoxWeighing
              key={code?.orderId}
              code={code}
              packageWeightError={packageWeightError}
              setPackageWeightError={setPackageWeightError}
              recordPackage={recordPackage}
              setRecordPackage={setRecordPackage}
              type='compare'
            />
          ))}

        </div>

        <div className={`${packageWeight ? 'hidden' : 'grid grid-cols-2 gap-4 overflow-auto'}`}>
          {packageToRecord.map((code) => (
            <PackageBoxWeighing
              key={code?.orderId}
              code={code}
              packageWeightError={packageWeightError}
              setPackageWeightError={setPackageWeightError}
              recordPackage={recordPackage}
              setRecordPackage={setRecordPackage}
              type='select'
            />
          ))}

        </div>


        <div className={`${(packageWeight && packageWeightErrorLength > 0)
          ? 'fixed inset-x-[0%] bottom-[11%] mx-[30px] flex flex-row justify-start gap-1 text-[#6B7F86]'
          : 'hidden'}`}>
          <p className='font-bold'>
            {`${packageWeightErrorLength} ${packageWeightErrorLength === 1 ? 'Paquete':'Paquetes'} `}
          </p>
          <p className='font-normal'>
            {`${packageWeightErrorLength === 1 ? 'incorrecto':'incorrectos'} (Total ${codes.length})`}
          </p>
        </div>

        <div className="fixed inset-x-[0%] bottom-[4%] z-10 flex justify-center ">
          <button
            type='button'
            onClick={handleClick}
            disabled={(packageWeight)  ? (codes.length !== packageWeightError.length) : recordPackage === '' }
            className={`mx-[30px]  mt-4 flex w-[315px] flex-row items-center 
            justify-center rounded-full py-1 text-center text-[17px] 
            ${(packageWeight ? (codes.length === packageWeightError.length) : (recordPackage !== ''))
            ? ' bg-[#0F77F0] text-white'
            : ' bg-[#CCD9DE] text-[#6B7F86]'}`}
          >
            {textButton}
            <svg
              className={`${packageWeight ? 'ml-2': 'hidden'}`}
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 12L8.6 10.55L12.15 7H0V5H12.15L8.6 1.45L10 0L16 6L10 12Z"
                fill={(codes.length === packageWeightError.length) ? 'white': '#6B7F86'}
              />
            </svg>
          </button>
        </div>

      </div>
      <div className={`h-full ${isRecording ? '': 'hidden'}`}>
        <WeighingRecord
          recordPackage={recordPackage}
          setPackageWeightError={setPackageWeightError}
          setIsRecording={setIsRecording}
          isRecording={isRecording}
        />
      </div>
      <BottomModal
        show={showModal}
        handleClose={handleCloseModal}
        textButton="Listo"
        text={(codes.length > 1) ? `${codes.length} Paquetes Recepcionados`: 'Paquete Recepcionado'}
      />

    </div>

  )
}

export default Weighing
