import { React, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import QrScannerLayout from '../../components/QrScanner/QrScannerLayout'
import ScanInformationBox from '../../components/shared/Box/ScanInformationBox'
import { SCAN_FLOWS_ICONS } from '../../constants/icons'
import Button from '../../components/shared/Button'
import Notification from '../../components/shared/Notification'
import { ScanContext } from '../../contexts/ScanContext'
import ROUTES from '../../constants/routes'
import { getPackageInformation } from '../../helpers/requests/packagesInformation'
import Modal from '../../components/shared/Modal/Modal'
import { ingressPackage } from '../../helpers/requests/statesChanges'
import { locationFormat } from '../../helpers/storage'
import PackageInformation from '../../components/shared/PackageInformation'
import Loader from '../../components/shared/Loader'
import {
  PACKAGE_BOUGHT,
  PACKAGE_DELIVERED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PICKED_UP,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_STORED
} from '../../constants/packageStatus'

const IdentifyPackage = () => {
  const { codes, setCodes, singleScan, setSingleScan, setScanning } = useContext(ScanContext)

  const [loadingIngress, setLoadingIngress] = useState(false)
  const [notIngressed, setNotIngressed] = useState(false)
  const [notIngressedCode, setNotIngressedCode] = useState(null)

  const [submitError, setSubmitError] = useState(false)
  const [repeatedCode, setRepeatedCode] = useState(false)
  const [statusError, setStatusError] = useState(false)
  const [storedLocation, setStoredLocation] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    setSubmitError(false)
    setRepeatedCode(false)
    setCodes([])
    setNotIngressed(false)
    setNotIngressedCode(null)
    setStatusError(false)
    setStoredLocation(null)
  }, [setCodes])

  const handleCasesSale = (sale, textCode) => {
    if (codes.includes(sale.pinflagId)) {
      setRepeatedCode(true)
    } else if (
      [PACKAGE_BOUGHT, PACKAGE_PROCESSED, PACKAGE_IN_TRANSIT, PACKAGE_ON_DELIVERY].includes(
        sale.packageStatus
      )
    ) {
      setNotIngressed(true)
      setNotIngressedCode(textCode)
    } else if (sale.packageStatus === PACKAGE_RECEIVED) {
      setCodes([...codes, { pinflagId: sale.pinflagId, orderId: sale.orderId }])
      if (singleScan) navigate(ROUTES.STORAGE)
    } else if (sale.packageStatus === PACKAGE_STORED) {
      setSingleScan(true)
      setStoredLocation(sale.location)
      setCodes([{ pinflagId: sale.pinflagId, orderId: sale.orderId }])
    } else if ([PACKAGE_PICKED_UP, PACKAGE_DELIVERED].includes(sale.packageStatus)) {
      setStoredLocation(sale.location)
      setStatusError(true)
    }
  }

  const handleCheckCode = async (textCode) => {
    try {
      const sale = await getPackageInformation(textCode)
      handleCasesSale(sale, textCode)
    } catch (error) {
      setSubmitError(true)
    }
  }

  const handleCloseIngress = () => {
    setNotIngressed(false)
    setNotIngressedCode(null)
    setScanning(false)
  }

  const handleIngress = async () => {
    setLoadingIngress(true)
    try {
      const NewCode = await ingressPackage(notIngressedCode)
      setCodes([...codes, NewCode])
      if (singleScan) navigate(ROUTES.STORAGE)
    } catch (error) {
      setSubmitError(true)
    } finally {
      setLoadingIngress(false)
      handleCloseIngress()
    }
  }

  return (
    <div className="h-full w-full overflow-hidden bg-light-gray">
      <div className="h-full">
        <QrScannerLayout
          name="Almacenar"
          color="border-purple"
          backgroundColor="bg-purple"
          singleScan={singleScan}
          validateCode={handleCheckCode}
          codeType="pinflag"
          haveScanned={codes.length > 0}
          switchScanType
        >
          {!singleScan && (
            <>
              <ScanInformationBox
                icon={SCAN_FLOWS_ICONS.packageBlue}
                title="Grupo"
                body={`${codes.length} Pedido${codes.length > 1 ? 's' : ''}`}
                codes={codes}
              />
              <Link to={ROUTES.STORAGE} className="w-full">
                <Button color="bg-blue" textColor="text-white" shadow="drop-shadow-blue">
                  Continuar
                </Button>
              </Link>
            </>
          )}
          {storedLocation && !statusError && (
            <PackageInformation
              title="Pedido Almacenado"
              infoText="Has identificado un pedido que ya ha sido almacenado, puedes cambiar la ubicación si lo deseas."
              packageBoxTitle="Orden de venta"
              packageBoxBody={codes[0].orderId}
              location={storedLocation}
              firstButton={{ text: 'Cambiar Ubicación', onClick: () => navigate(ROUTES.STORAGE) }}
              secondButton={{
                text: 'Cancelar',
                onClick: () => {
                  setStoredLocation(null)
                  setCodes([])
                }
              }}
            />
          )}
        </QrScannerLayout>
        <Notification show={submitError} handleClose={() => setSubmitError(false)} error>
          El código ingresado es inválido
        </Notification>
        <Notification show={repeatedCode} handleClose={() => setRepeatedCode(false)} error>
          Ya ingresaste este código
        </Notification>
        <Notification
          show={statusError}
          handleClose={() => {
            setStatusError(false)
            setStoredLocation(null)
          }}
          error
        >
          Pedido almacenado en {locationFormat(storedLocation)}
        </Notification>
        <Modal show={notIngressed} handleClose={handleCloseIngress}>
          <div className="my-8">Este pedido no ha sido ingresado, ¿deseas ingresarlo ahora?</div>
          {loadingIngress ? (
            <Loader />
          ) : (
            <>
              <Button
                color="bg-blue"
                textColor="text-white"
                shadow="drop-shadow-blue"
                onClick={handleIngress}
              >
                Ingresar ahora
              </Button>
              <Button textColor="text-text-gray" onClick={handleCloseIngress}>
                Más tarde
              </Button>
            </>
          )}
        </Modal>
      </div>
    </div>
  )
}

export default IdentifyPackage
