const ROUTES = {
  RECEIVED: '/recibido',
  INGRESS: '/ingresar',
  STORAGE: '/almacenar',
  DISTRIBUTION: '/distribucion',
  WEIGHING: '/distribucion/pesaje',
  IDENTIFY_PACKAGE: '/almacenar/identificar',
  DELIVER: '/entregar',
  PACKAGE_DETAIL: '/pedidos/:packageId',
  PACKAGES: '/pedidos',
  CLAIM: '/reclamar',
  CLAIM_PACKAGE: '/reclamar/pedido'
}

export default ROUTES
