import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { SCAN_FLOWS_ICONS } from '../constants/icons'
import ScanInformationBox from './shared/Box/ScanInformationBox'
import { ScanContext } from '../contexts/ScanContext'
import ROUTES from '../constants/routes'

import PackageInformation from './shared/PackageInformation'
import Button from './shared/Button'

const IngressFlow = ({ packageAlreadyStored, setPackageAlreadyStored }) => {
  const { codes, setCodes, singleScan } = useContext(ScanContext)
  const navigate = useNavigate()

  if (Object.keys(packageAlreadyStored).length > 0) {
    return (
    <PackageInformation
      title="Pedido Almacenado"
      infoText="Has identificado un pedido que ya ha sido almacenado, puedes cambiar la ubicación si lo deseas."
      packageBoxTitle="Orden de venta"
      packageBoxBody={packageAlreadyStored?.packageCodes?.orderId}
      location={packageAlreadyStored?.location}
      firstButton={{ text: 'Cambiar Ubicación', onClick: () => {
        setCodes([packageAlreadyStored?.packageCodes])
        navigate(ROUTES.STORAGE)
        }
      }}
      secondButton={{
        text: 'Cancelar',
        onClick: () => setPackageAlreadyStored({})
      }}
    />)
  }

  return (
    <>
      {singleScan ? (
        <ScanInformationBox
          icon={SCAN_FLOWS_ICONS.packageBlue}
          title="Orden de venta"
          body={codes[codes.length - 1]?.orderId}
        />
      ) : (
        <ScanInformationBox
          icon={SCAN_FLOWS_ICONS.packageBlue}
          title="Grupo"
          body={`${codes.length} Pedido${codes.length > 1 ? 's' : ''}`}
        />
      )}
      <div className="mx-2 my-0.5 flex">
        <Link to="/" className="w-full">
          <Button textColor="text-text-gray">Más tarde</Button>
        </Link>
        <Link to={ROUTES.STORAGE} className="w-full">
          <Button color="bg-blue" textColor="text-white" shadow="drop-shadow-blue">
            Almacenar
          </Button>
        </Link>
      </div>
    </>
  )
}

export default IngressFlow
