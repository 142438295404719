import { React } from 'react'

const Button = ({ color, onClick, inactive, textColor, shadow, children }) => (
  <div
    className={`${
      inactive
        ? 'cursor-default bg-light-blue text-medium-gray'
        : `${color} hover:opacity-70 ${textColor} ${shadow}`
    } mx-auto my-2 w-full max-w-3xl whitespace-nowrap rounded-3xl py-4`}
    role="button"
    tabIndex="0"
    onClick={inactive ? undefined : onClick}
  >
    {children}
  </div>
)

export default Button
