import { React, useContext, useEffect, useState } from 'react'

import QrScannerSecondLayout from '../../components/QrScanner/QrScannerSecondLayout'
import Notification from '../../components/shared/Notification'
import { ScanContext } from '../../contexts/ScanContext'
import ROUTES from '../../constants/routes'
import { getPackageInformation } from '../../helpers/requests/packagesInformation'
import WeighingFlow from '../../components/WeighingFlow'


const WeighingScan = () => {
  const [messageError, setMessageError] = useState('')
  const { codes, setCodes, singleScan } = useContext(ScanContext)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [packageAlreadyStored, setPackageAlreadyStored] = useState({})

  useEffect(() => {
    setSubmitSuccess(false)
    setCodes([])
  }, [setCodes])

  const handlePackageResponse = async (distributionPackage) => {
    const { orderId, packageId } = distributionPackage
    const scannedCode = { orderId, packageId }

    const orderIds = codes.map(code => code.orderId)

    if (orderIds.includes(orderId)) setMessageError('Ya ingresaste este código')
    else {
      setCodes([...codes, scannedCode])
      setSubmitSuccess(true)
    }
  }

  const handleCheckCode = async (textCode) => {

    if (codes.includes(textCode)) {
      setMessageError('Ya ingresaste este código')
      return
    }
    try {
      const distributionPackage= await getPackageInformation(textCode)
      if (distributionPackage.packageType !== 'distribution') {
        setMessageError('Este paquete no corresponde a distribución')
        return
      }
      handlePackageResponse(distributionPackage)
    } catch (error) {
      setMessageError('El código ingresado es inválido')
    }

  }

  return (
    <div className="h-full w-full overflow-hidden bg-light-gray">
      <div className="h-full">
        <QrScannerSecondLayout
          name="Scanner"
          text="Recepciona cuantos paquetes necesites"
          color="border-blue"
          backgroundColor="bg-transparent"
          singleScan={singleScan}
          validateCode={handleCheckCode}
          codeType="pinflag"
          haveScanned={codes.length > 0 || Object.keys(packageAlreadyStored).length > 0}
          switchScanType
        >
          <WeighingFlow
            textButton="Pesar"
            linkButton={ROUTES.WEIGHING}
            packageAlreadyStored={packageAlreadyStored}
            setPackageAlreadyStored={setPackageAlreadyStored}
          />
        </QrScannerSecondLayout>
        <Notification show={messageError !== ''} handleClose={() => setMessageError('')} error>
          {messageError}
        </Notification>
        <Notification show={submitSuccess} handleClose={() => setSubmitSuccess(false)}>
          Pedido ingresado con éxito
        </Notification>
      </div>
    </div>
  )
}
export default WeighingScan
