import React from 'react'

const Box = ({ children, shadow }) => (
  <div className={`max-w-md rounded-lg bg-white text-text-default ${shadow || ''}`}>
    {children}
  </div>
)


export default Box
