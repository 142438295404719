import { React, useContext, useState } from 'react'
import QrReader from 'react-qr-scanner'

import { typeCodeIcon } from '../../constants/icons'
import { ScanContext } from '../../contexts/ScanContext'

import TypeCode from './TypeCode'

const QrScanner = ({
  color,
  handleScan,
  handleCode,
  codeType,
  cameraConstrain,
  setCameraConstrain
}) => {
  const { scanning } = useContext(ScanContext)
  const [manually, setManually] = useState(false)
  const [disabledScan, setDisableScan] = useState(false)

  const handleCameraError = () => {
    if (cameraConstrain === undefined) {
      setManually(true)
      setDisableScan(true)
    } else {
      setCameraConstrain(undefined)
    }
  }

  return (
    <>
      <div className="absolute top-0 z-0 h-[100%] w-full overflow-hidden">
        {scanning && !disabledScan ? (
          <QrReader
            className="h-full max-h-screen w-full object-cover"
            onScan={handleScan}
            onError={handleCameraError}
            constraints={cameraConstrain}
          />
        ) : (
          <div className="h-full max-h-screen w-full bg-camera-background" />
        )}
        <div className="absolute top-0 h-[80%] w-full text-sm text-white">
          <div className="h-20 bg-transparent-black sm:h-[10vw] md:h-40 2xl:h-[5vw] xs:h-24" />
          <div className="flex">
            <div className="w-[25vw] bg-transparent-black sm:w-[32.5vw] xs:w-[22.5vw]" />
            <div
              className={`h-[50vw] w-[50vw] border ${color} max-h-1/3-screen 
              sm:h-[35vw] sm:w-[35vw] xs:h-[55vw] xs:w-[55vw]`}
            />
            <div className="w-[25vw] bg-transparent-black sm:w-[32.5vw] xs:w-[22.5vw]" />
          </div>
          <div className="h-[80vh] bg-transparent-black pt-4">
            <div className="mx-2">
              Escanea el Código Qr {codeType === 'pinflag' ? 'del Pedido' : 'de la Ubicación'}{' '}
            </div>
            <div>o</div>
            <button
              className="m-auto my-2 flex rounded-lg bg-transparent-black2 px-4 py-2 text-white hover:opacity-70"
              onClick={() => setManually(true)}
              type="button"
            >
              <img src={typeCodeIcon} alt="Digita código" className="my-auto mr-1 w-4" />
              Digita el Código {codeType === 'pinflag' ? 'del pedido' : 'de la Ubicación'}
            </button>
          </div>
        </div>
      </div>
      {manually && (
        <TypeCode handleCode={handleCode} setManually={setManually} codeType={codeType} />
      )}
    </>
  )
}

export default QrScanner
