import { React, useEffect, useState } from 'react'

import useSession from '../hooks/useSession'
import { loginRequest } from '../helpers/requests/session'

import PasswordInput from './shared/inputs/PasswordInput'

const LoginForm = ({ setLoading, failedLogin, setFailedLogin }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login } = useSession()

  useEffect(() => {
    setEmail('')
    setPassword('')
    return () => {
      setEmail('')
      setPassword('')
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const body = {
      email,
      password
    }
    try {
      const response = await loginRequest(body)
      login(response.data)
    } catch {
      setFailedLogin(true)
      setLoading(false)
    }
  }

  return (
    <form className="m-auto flex max-w-lg flex-col space-y-6 font-light" onSubmit={handleSubmit}>
      {failedLogin && <h4>Credenciales incorrectas, intenta nuevamente</h4>}
      <input
        type="text"
        className="rounded-lg border-b px-4 py-3 focus:border-pinflag-dark focus:outline-none"
        placeholder="Correo electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <PasswordInput password={password} setPassword={setPassword} />
      <input
        type="submit"
        className="cursor-pointer rounded-full bg-pinflag-dark py-4 text-white"
        value="Continuar"
      />
    </form>
  )
}

export default LoginForm
