import apiClient from '../../axiosConfig/apiClient'
import { SALE_URL, PACKAGES_BY_STATUS, PACKAGE_PERSONALIZATION } from '../../constants/api'

export const getPackageInformation = async (saleId) => {
  const response = await apiClient.get(SALE_URL(saleId))

  return response.data
}

export const getPackagesByStatus = async (status) => {
  const response = await apiClient.get(PACKAGES_BY_STATUS(status))

  return response.data
}

export const getPackagePersonalization = async (packageId) => {
  const response = await apiClient.get(PACKAGE_PERSONALIZATION(packageId))

  return response.data
}
